<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row d-flex justify-content-between">
          <div class="col-md-2 pr-2">
            <h2 class="content-title">
              <a @click="$router.go(-1)" style="cursor: pointer"
                ><i class="fas fa-angle-left mr-2"></i><span>Ledger</span>
              </a>
            </h2>
          </div>
        </div>
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <div id="myTabContent" class="tab-content">
                <div
                  id="tab_1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  class="tab-pane fade active show"
                >
                  <div class="worker_serch_warp">
                    <div class="row">
                      <div class="col-lg-3 mb-2">
                        <div class="search-form position-relative">
                          <input
                            type="text"
                            class="form-control"
                            v-model="filterObj.keyword"
                             v-on:blur="getList(1)"
                            placeholder="Search"
                          />
                          <div class="input-group-prepend position-absolute">
                            <button class="" type="button" id="button-addon1">
                              <img src="/static/img/search.svg" width="15" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                        <div
                          class="add_btn_wrapper d-flex flex-row justify-content-end"
                        >
                          <Multiselect
                            ref="branch_id_multiselect"
                            name="branch_id"
                            mode="single"
                            trackBy="name"
                            label="name"
                            valueProp="branch_id"
                            placeholder="Select Branch"
                            class="form-control"
                            v-model="filterObj.branch_ids"
                            :options="branchList"
                            searchable="true"
                          />
                        </div>
                      </div>
                      <div class="col-lg-3 mb-2">
                        <VueDatePicker
                          placeholder="Select Date Range"
                          :format="'dd/MM/yyyy'"
                          style="height: 46px !important"
                          v-model="date"
                          range
                          :type="boolean"
                          :default="true"
                          :enable-time-picker="false"
                        >
                        </VueDatePicker>
                      </div>
                      <div class="col-lg-3 mb-2">
                        <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                          >Search
                        </a>
                        <a @click="reset()" class="btn btn-brand-03 mr-2"
                          >Reset
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div
                          class="col-lg-12 d-flex flex-row justify-content-end pb-3"
                        >
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportReport()"
                            class="btn btn-brand-01 mr-2"
                            id="cibil_report"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                             Export Summary
                          </button>
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportReportStatement()"
                            class="btn btn-brand-01 mr-2"
                            id="statement_report"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                           Export Statement 
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'branches.name')"
                              >
                                Branch
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'admin_users.name')"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="
                                  sorting($event, 'admin_users.mobile_number')
                                "
                              >
                                Mobile Number
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="
                                  sorting($event, 'admin_users.user_type')
                                "
                              >
                                Type
                              </th>
                              <th scope="col">Balance</th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'admin_users.status')"
                              >
                                Status
                              </th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="7" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td>
                                <template
                                  v-for="(branch, i) in item?.branches"
                                  :key="branch.id"
                                >
                                  <span v-if="i + 1 != item.branches.length">
                                    {{ branch.name + ", " }}
                                  </span>
                                  <span v-else> {{ branch.name }}</span>
                                </template>
                              </td>
                              <td>{{ item?.name }}</td>
                              <td>{{ item?.mobile_number }}</td>
                              <td>  {{$helperService.getTitleCase(item?.user_type)}}</td>
                              <td>{{ $helperService.getFormattedCurrency(item?.total_balance) }}</td>
                              <td> <span :style="item.status == 'ACTIVE' ? 'color:#5CC82A' : 'color: #DADADA'">{{
                                $helperService.getTitleCase(item.status) }}</span>
                              </td>
                              
                             
                              <td>
                                <a
                                  title="View"
                                  class="mx-2"
                                  v-if="access.can_read == 1"
                                  style="cursor: pointer"
                                  @click="
                                    onEmployeeAccountDetail(
                                      item,
                                      access.can_update
                                    )
                                  "
                                  ><img src="/static/img/eye-icon.svg" alt="" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="getList(1)"
                          ref="EMPLOYEE_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="EMPLOYEE_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">View Expense</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <img :src="url" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="showDownloadAlert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showDownloadAlert"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div
        class="modal-dialog modal-md modal-dialog-centered modal-md"
        role="document"
      >
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeDownloadAlertPopup()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Download Alert</span>
            </h5>
          </div>
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="row">
              <div class="col-lg-12">
                Report Will be Available soon.
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeDownloadAlertPopup()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import moment from "moment";
export default {
  name: "EmployeeManagment",
  components: {
    Pagination,
    ErrorComponent,
    Multiselect,
    VueDatePicker,
  },

  data() {
    return {
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      date: "",
      loader: false,
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
        branch_ids: "",
      },
      delete_id: "",
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      employee_expense_active_tab: "PENDING",
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    // this.employee_expense_active_tab = localStorage.getItem("employee_expense_active_tab")
    //   ? localStorage.getItem("employee_expense_active_tab")
    //   : "PENDING";
    this.$storeService.commit("setTitle", "Ledger");
    // this.changeTab(this.employee_expense_active_tab);
    this.getList(1);
  },
  methods: {
    // changeTab(tab) {
    //   this.employee_expense_active_tab = tab;
    //   localStorage.setItem("employee_expense_active_tab", tab);
    //   if (tab == "PENDING") {
    //     window.$("#tab-1").click();
    //     this.getList(1);
    //   } else if (tab == "APPROVED") {
    //     window.$("#tab-2").click();
    //     this.getList(1);
    //   } else if (tab == "REJECTED") {
    //     window.$("#tab-3").click();
    //     this.getList(1);
    //   } else {
    //     window.$("#tab-1").click();
    //     this.getList(1);
    //   }
    // },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "admin_users.created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids: "",
        from_date: "",
        to_date: "",
      };
      this.date = "";
      this.getList(1);
    },
    onEmployeeAccountDetail(item, can_update) {
      this.$router.push(
        "/employee-account/" + item.uuid + "/" + item.name + "/" + can_update
      );
    },
    addOfficeExpense() {
      localStorage.setItem("expense_status", "EMPLOYEE");
      this.$router.push("/add-expense");
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.EMPLOYEE_PAGINATION.itemsPerPage;

      this.getList(1);
    },
    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              false
            );
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              true
            );
          }
        }
      }
      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }
      // var checkValue = false;
      // if (listLength === 0) {
      //   checkValue = true;
      // }
      // if (checkValue) {
      //   if (this.$refs[errorCompRef]) {
      //     this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
      //   }
      // }
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.date[1])).format("Y-MM-DD");
      } else {
        this.filterObj.from_date = "";
        this.filterObj.to_date = "";
      }

      this.list = [];
      this.loader = true;
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "admin-users/ledger",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          if (res.access.can_read == 0) {
            return this.$router.go(-1);
          }
          this.list = res.list;
          this.access = res.access;
          this.setPagination(
            page,
            res.count,
            res.list.length,
            "EMPLOYEE_PAGINATION",
            "EMPLOYEE_ERROR_COMPONENT"
          );
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "get-summary-export",
          _body: this.filterObj,
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onExportReportStatement() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "get-statement-export",
          _body: this.filterObj,
          _buttonId: "statement_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show");
      } else {
        console.log(url, filename);
        var anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = filename;
        anchorElement.target = "_blank";
        document.body.appendChild(anchorElement);
        console.log(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
      }
    },
    closeDownloadAlertPopup() {
      window.$("#showDownloadAlert").modal("hide");
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";
      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
  },
};
</script>
