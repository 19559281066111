<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
              <li class="nav-item">
                <a
                  id="tab-1"
                  @click="changeTab('PENDING')"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  class="nav-link active"
                  >Pending</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tab-2"
                  @click="changeTab('VERIFIED')"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="false"
                  class="nav-link"
                  >Verified</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tab-3"
                  @click="changeTab('REJECTED')"
                  data-toggle="tab"
                  href="#tab_3"
                  role="tab"
                  aria-controls="tab_3"
                  aria-selected="false"
                  class="nav-link"
                  >Rejected</a
                >
              </li>
            </ul>
            <div id="myTabContent" class="tab-content">
              <div class="worker_serch_warp">
                <div class="row">
                  <div class="col-lg-3 mb-2">
                    <div class="search-form position-relative">
                      <input
                        type="text"
                        class="form-control"
                        v-model="filterObj.keyword"
                         v-on:blur="getList(1)"
                        placeholder="Search"
                      />
                      <div class="input-group-prepend position-absolute">
                        <button class="" type="button" id="button-addon1">
                          <img src="/static/img/search.svg" width="15" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                    <div
                      class="add_btn_wrapper d-flex flex-row justify-content-end"
                    >
                      <Multiselect
                        ref="branch_id_multiselect"
                        name="branch_id"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="branch_id"
                        placeholder="Select Branch"
                        class="form-control"
                        v-model="filterObj.branch_ids"
                        :options="branchList"
                        searchable="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <VueDatePicker
                      placeholder="Select Payment Date"
                      style="height: 46px !important"
                      v-model="date"
                      range
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                      >Search
                    </a>
                    <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                  </div>
                </div>
              </div>
              <div class="card rounded-5">
                <div class="card-body p-3 p-lg-3">
                  <!-- <div class="row">
                    <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                      <button
                        v-if="access.can_print == 1"
                        @click="onExportReport()"
                        class="btn btn-brand-01 mr-2"
                        id="cibil_report"
                      >
                       <i class="fa fa-download" aria-hidden="true"></i> Company Royalty Export 
                      </button>
                    </div>
                  </div> -->
                  <div class="row">
                    <div
                      class="col-lg-12 d-flex flex-row justify-content-end pb-3"
                    >
                      <button
                        v-if="access.can_print == 1"
                        @click="onExportReport()"
                        class="btn btn-brand-01 mr-2"
                        id="cibil_report"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i> Export
                      </button>

                      <button
                        v-if="
                          isShowApproveBtn &&
                          emi_payment_active_tab == 'PENDING'
                        "
                        type="button"
                        class="btn btn-brand-01 wb-cancel-btn mx-2"
                        id="approve-btn"
                        @click="onApprove('VERIFIED')"
                      >
                        Approve
                      </button>
                      <button
                        v-if="
                          isShowApproveBtn &&
                          emi_payment_active_tab == 'PENDING'
                        "
                        type="button"
                        class="btn btn-danger wb-cancel-btn"
                        id="reject-btn"
                        @click="onApprove('REJECTED')"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-primary mg-b-0">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            v-if="emi_payment_active_tab == 'PENDING'"
                          >
                            <div class="" v-if="list.length > 0">
                              <input
                                type="checkbox"
                                v-bind:true-value="1"
                                v-bind:false-value="0"
                                @change="selectAll($event)"
                                id="create"
                                v-model="select_all"
                              />
                            </div>
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting(
                                $event,
                                'loan_applications.application_id'
                              )
                            "
                          >
                            Loan ID
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_transactions.utr_number')
                            "
                          >
                            UTR No
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting(
                                $event,
                                'loan_applications.applicant_name'
                              )
                            "
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="sorting($event, 'branches.name')"
                          >
                            Branch
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_applications.mobile_number')
                            "
                          >
                            Mobile Number
                          </th>
                          <th scope="col">Address</th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_transactions.trx_amount')
                            "
                          >
                            Amount Paid
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_transactions.principal')
                            "
                          >
                            Principal
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_transactions.interest')
                            "
                          >
                            Interest
                          </th>
                          <!-- <th scope="col" class="sorting" @click="sorting($event, 'loan_transactions.company_royalty')">
                           Royalty Amount
                          </th> -->

                          <th
                            scope="col"
                            class="sorting"
                            @click="
                              sorting($event, 'loan_transactions.payment_date')
                            "
                          >
                            Payment Date
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="sorting($event, 'approved_users.name')"
                            v-if="emi_payment_active_tab == 'VERIFIED'"
                          >
                            Verified By
                          </th>

                          <th
                            scope="col"
                            class="sorting"
                            @click="sorting($event, 'rejected_users.name')"
                            v-if="emi_payment_active_tab == 'REJECTED'"
                          >
                            Reason
                          </th>
                          <th
                            scope="col"
                            class="sorting"
                            @click="sorting($event, 'rejected_users.name')"
                            v-if="emi_payment_active_tab == 'REJECTED'"
                          >
                            Rejected By
                          </th>

                          <th scope="col" class="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loader">
                          <td
                            :colspan="
                              emi_payment_active_tab == 'PENDING' ? 14 : (emi_payment_active_tab == 'REJECTED' ? 14 : 13)
                            "
                            class="text-center"
                          >
                            <div
                              class="spinner-border spinner-color"
                              role="status"
                            ></div>
                          </td>
                        </tr>
                        <tr v-for="item in list" :key="item.id">
                          <td class=""    v-if="emi_payment_active_tab == 'PENDING'">
                            <input
                              type="checkbox"
                              v-bind:true-value="1"
                              v-bind:false-value="0"
                              class="'custom-control-input'"
                              @change="checkPermission($event, item)"
                              :id="'create_' + i"
                              v-model="item.is_checked"
                            />
                          </td>
                          <td>{{ item?.loan?.application_id }}</td>
                          <td>
                            <span v-if="item.utr_number">{{
                              item?.utr_number
                            }}</span>
                            <span v-else>-</span>
                          </td>
                          <td>{{ item?.loan?.applicant_name }}</td>
                          <td>{{ item?.loan?.branch?.name }}</td>
                          <td>{{ item?.loan?.mobile_number }}</td>
                          <td>{{ item?.loan?.home_address?.address }}</td>
                          <td>
                            {{
                              $helperService.getFormattedCurrency(
                                item.trx_amount
                              )
                            }}
                          </td>
                          <td>
                            {{
                              $helperService.getFormattedCurrency(
                                item.principal
                              )
                            }}
                          </td>
                          <td>
                            {{
                              $helperService.getFormattedCurrency(item.interest)
                            }}
                          </td>
                          <!-- <td>
                            {{ $helperService.getFormattedCurrency(item.company_royalty) }}
                          </td> -->
                          <td v-if="item.payment_date">
                            {{
                              $helperService.getFormattedDateOnly(
                                item.payment_date
                              )
                            }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="emi_payment_active_tab == 'VERIFIED'">
                            {{ item?.approved_by?.name }}
                          </td>
                          <td v-if="emi_payment_active_tab == 'REJECTED'">
                            {{ item?.narration }}
                          </td>
                          <td v-if="emi_payment_active_tab == 'REJECTED'">
                            {{ item?.rejected_by?.name }}
                          </td>
                          <td>
                            <a
                              title="View"
                              class="mx-2"
                              style="cursor: pointer"
                              @click="view(item, access.can_update)"
                              ><img src="/static/img/eye-icon.svg" alt=""
                            /></a>

                            <a
                              title="Delete"
                              class="mx-2"
                              v-if="
                                access.can_delete == 1 &&
                                item?.created_by != null
                              "
                              style="cursor: pointer"
                              @click="onDeleteEvent(item)"
                              ><img src="/static/img/delete-icon.svg" alt=""
                            /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageChange"
                          @items-per-page-change="itemsPerPageChange"
                          ref="rolePagination"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="showDownloadAlert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showDownloadAlert"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div
        class="modal-dialog modal-md modal-dialog-centered modal-md"
        role="document"
      >
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeDownloadAlertPopup()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Download Alert</span>
            </h5>
          </div>
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="row">
              <div class="col-lg-12">
                Sorry, We don't have any data to export.
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeDownloadAlertPopup()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="openRejectNarration"
      tabindex="-1"
      role="dialog"
      aria-labelledby="openRejectNarration"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeNarrationModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div
            class="modal-header justify-content-center"
            v-if="reason_type == 'REJECTED'"
          >
            <h5 class="modal-title font-22">
              <span>Reason</span>
            </h5>
          </div>
          <div
            class="modal-header justify-content-center"
            v-if="reason_type == 'VERIFIED'"
          >
            <h5 class="modal-title font-22">
              <span>Confirmation</span>
            </h5>
          </div>
          <div class="modal-body text-center" v-if="reason_type == 'VERIFIED'">
            <h3>Are you sure you want to verified ?</h3>
          </div>
          <div
            class="modal-footer border-0 justify-content-center mb-3"
            v-if="reason_type == 'VERIFIED'"
          >
            <button
              id="cancel-btn"
              @click="closeNarrationModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-brand-01 wb-cancel-btn"
              id="status-btn"
              @click="onAddRejectNarration"
            >
              Verified
            </button>
          </div>
          <Form
            @submit="onAddRejectNarration"
            class="columns column is-multiline is-12"
            ref="onAddRejectNarration"
            v-if="reason_type == 'REJECTED'"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="form-group">
                  <label
                    >Reason
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="level"
                    rules="required:reason,true"
                    :validateOnInput="true"
                    v-model="narration"
                  >
                    <textarea
                      v-bind="field"
                      rows="5"
                      cols="5"
                      valueProp="id"
                      placeholder="Enter Reject Reason"
                      class="form-control"
                      v-model="narration"
                    />
                  </Field>
                  <ErrorMessage name="level" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeNarrationModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-danger wb-cancel-btn"
                id="status-btn"
                v-if="reason_type == 'REJECTED'"
              >
                Reject
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteClick" ref="deleteClick" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import VueDatePicker from "@vuepic/vue-datepicker";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import moment from "moment";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "LoanDisbursed",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueDatePicker,
  },

  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        branch_ids: "",
        sort_by: "loan_transactions.created_at",
        sort_order: "desc",
        row_per_page: 10,
        from_date: "",
        to_date: "",
      },
      loan_id: null,
      trans_id: null,
      emi_payment_active_tab: "PENDING",
      date: "",
      approvalObj: {
        status: "",
        items: [],
      },
      select_all: "",
      narration: "",
      reason_type: "",
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    this.emi_payment_active_tab = localStorage.getItem("emi_payment_active_tab")
      ? localStorage.getItem("emi_payment_active_tab")
      : "PENDING";
    this.$storeService.commit("setTitle", "EMI Payment Verification");
    this.changeTab(this.emi_payment_active_tab);
  },
  methods: {
    changeTab(tab) {
      this.emi_payment_active_tab = tab;
      localStorage.setItem("emi_payment_active_tab", tab);
      if (tab == "PENDING") {
        window.$("#tab-1").click();
        this.getList(1);
      } else if (tab == "VERIFIED") {
        window.$("#tab-2").click();
        this.getList(1);
      } else if (tab == "REJECTED") {
        window.$("#tab-3").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "loan_transactions.created_at",
        sort_order: "desc",
        row_per_page: 10,
        branch_ids: "",
        from_date: "",
        to_date: "",
      };
      this.date = "";
      this.getList(1);
    },
    view(item, can_update) {
      this.$router.push(
        "/view-emi_payment/" +
          item.uuid +
          "/" +
          item.loan.uuid +
          "/" +
          can_update
      );
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      this.filterObj.status = this.emi_payment_active_tab;

      if (!this.filterObj.branch_ids) {
        this.filterObj.branch_ids = "";
      }

      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format(
          "YYYY-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.date[1])).format(
          "YYYY-MM-DD"
        );
      } else {
        this.filterObj.from_date = "";
        this.filterObj.to_date = "";
      }

      this.list = [];
      this.loader = true;
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "emi-transactions",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          if (res.access.can_read == 0) {
            return this.$router.go(-1);
          }
          this.access = res.access;
          this.list = res.list;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle(
                "Sorry, There is no record found.",
                true
              );
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    onDeleteEvent(item) {
      this.loan_id = item.loan.uuid;
      this.trans_id = item.uuid;
      this.$refs.deleteClick.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      );
    },
    onDeleteClick() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action:
            "loan/" + this.loan_id + "/transaction/" + this.trans_id + "",
        })
        .then((res) => {
          this.loan_id = null;
          this.trans_id = null;
          this.$refs.deleteClick.closeModal();
          this.$toast.success(res.message, { position: "top-right" });
          this.getList(1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "get-verification-export",
          _body: this.filterObj,
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show");
      } else {
        console.log(url, filename);
        var anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = filename;
        anchorElement.target = "_blank";
        document.body.appendChild(anchorElement);
        console.log(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
      }
    },
    closeDownloadAlertPopup() {
      window.$("#showDownloadAlert").modal("hide");
    },

    checkPermission(e, obj) {
      let value = {
        loan_id: obj?.loan?.uuid,
        loan_no: obj?.loan?.application_id,
        loan_trans_id: obj?.uuid,
      };

      if (e.target.checked) {
        this.approvalObj.items.push(value);
        obj.is_checked = 1;
      } else {
        obj.is_checked = 0;
        this.approvalObj.items = this.approvalObj.items.filter(
          (item) => item.loan_id !== obj.loan.uuid
        );
      }

      this.isShowApproveBtn = this.approvalObj.items.length > 0;
      this.select_all = this.list.every((item) => item.is_checked);

      console.log(this.approvalObj.items);
    },
    selectAll(e) {
      this.isShowApproveBtn = e.target.checked;
      this.approvalObj.items = [];

      this.list.forEach((item) => {
        if (e.target.checked) {
          item.is_checked = 1;
          this.approvalObj.items.push({
            loan_id: item?.loan?.uuid,
            loan_no: item?.loan?.application_id,
            loan_trans_id:item?.id,
          });
        } else {
          item.is_checked = 0;
          this.approvalObj.items = [];
        }
      });

      console.log(this.approvalObj.items);
    },
    onApprove(status) {
      if (this.approvalObj.items?.length > 0) {
        window.$("#openRejectNarration").modal("show");
        this.reason_type = status;
      } else {
        this.$toast.error("Please select atleast one application", {
          position: "top-right",
        });
      }
    },

    approveCloseModal() {
      window.$("#openRejectNarration").modal("hide");
    },
    onNarrtion() {
      window.$("#openRejectNarration").modal("show");
    },
    closeNarrationModal() {
      this.narration = null;
      this.reason_type = null;
      window.$("#openRejectNarration").modal("hide");
      if (this.reason_type == "REJECTED") {
        this.onClearForm("onAddRejectNarration");
      }
    },
    onAddRejectNarration() {
      this.approvalObj.status = this.reason_type;
      var button_id = "status-btn";
      this.reason_type === "REJECTED" ? this.approvalObj.narration = this.narration : null;
      this.$api
        .webRequest({
          _method: "PUT",
          _action:
            "bulk/transaction/status",
          _buttonId: button_id,
          _body: this.approvalObj,
        })
        .then(() => {
          this.closeNarrationModal();
          // this.$toast.success(res.message, { position: "top-right" });
          this.changeTab(this.emi_payment_active_tab);
          this.isShowApproveBtn = false;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>
