<template>
  <div class="content-body">
    <div class="component-section no-code">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-lg-12 d-flex justify-content-between">
            <h2 class="content-title">
              <a @click="$router.go(-1)" style="cursor: pointer"
                ><i class="fas fa-angle-left mr-2"></i>
                <span>View Loan Application</span>
              </a>
            </h2>
            <div
              class=""
              v-if="
                application_status != 'PENDING' ||
                application_status != 'PENDING_FOR_VERIFICATION'
              "
            >
              <button
                v-if="access.can_print == 1"
                id="generate-document"
                title="Loan Agreement Download"
                class="mx-1 btn btn-brand-01 wb-save-btn"
                style="cursor: pointer"
                @click="onGenerateFile()"
              >
                {{ generate_document_title }}
              </button>

              <a
                v-if="
                  access.can_print == 1 && loanApplication?.customer_noc != null
                "
                title="Loan Schedule Download"
                id="customer-noc-btn"
                class="mx-1 btn btn-brand-01 wb-save-btn"
                style="cursor: pointer"
                @click="
                  downloadPdf(
                    loanApplication?.customer_noc,
                    loanApplication?.customer_noc
                  )
                "
                ><img
                  src="/static/img/download-icon.svg"
                  alt=""
                  class="mx-1"
                />Customer NOC</a
              >
              <button
                v-if="
                  access.can_print == 1 &&
                  loanApplication?.loan_agreement !== null
                "
                title="Loan Agreement"
                class="mx-1 btn btn-brand-01 wb-save-btn"
                style="cursor: pointer"
                id="sanction-letter"
                @click="
                  downloadPdf(
                    loanApplication?.loan_agreement,
                    loanApplication?.application_id
                  )
                "
              >
                <img
                  v-if="loanApplication?.sanction_letter !== null"
                  src="/static/img/download-icon.svg"
                  alt=""
                  class="mx-1"
                />Loan Agreement
              </button>
              <button
                v-if="
                  access.can_print == 1 &&
                  loanApplication?.sanction_letter !== null
                "
                title="Sanction Letter"
                class="mx-1 btn btn-brand-01 wb-save-btn"
                style="cursor: pointer"
                id="sanction-letter"
                @click="
                  downloadPdf(
                    loanApplication?.sanction_letter,
                    loanApplication?.application_id
                  )
                "
              >
                <img
                  v-if="loanApplication?.sanction_letter !== null"
                  src="/static/img/download-icon.svg"
                  alt=""
                  class="mx-1"
                />Sanction Letter
              </button>
            </div>
          </div>

          <div class="col-lg-12">
            <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
              <li class="nav-item">
                <a
                  id="tab-1"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  class="nav-link active"
                  >Applicant Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tab-2"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  @click="getLOanApplicationInfo()"
                  aria-selected="false"
                  class="nav-link"
                  >Guarantor Details</a
                >
              </li>
            </ul>
            <div id="myTabContent" class="tab-content">
              <div
                id="tab_1"
                role="tabpanel"
                aria-labelledby="tab-1"
                class="tab-pane fade active show"
              >
                <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3 d-flex flex-row justify-content-between"
                    >
                      <h4 class="text-bolder pt-3">Basic Details</h4>
                      <div class="">
                        <button
                          v-if="running_loan_count > 0"
                          type="submit"
                          class="btn btn-brand-01 wb-save-btn mx-2"
                          id="loan-application-btn"
                        >
                          Update
                        </button>
                        <button
                          type="submit"
                          class="btn btn-brand-01 wb-save-btn mx-2"
                          id="edit_basic_info"
                          @click="openResetLoanDetail()"
                        >
                          Edit Applicant Info
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Application Id</h5>
                        </div>
                        <div class="col-lg-7">
                          <h5 class="text-bolder">
                            {{ loanApplication.application_id }}
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5
                            class="label-text"
                            v-if="loanApplication?.parent_loan != null"
                          >
                            Old Application Id
                          </h5>
                        </div>
                        <div class="col-lg-7">
                          <h5
                            class="text-bolder font-weight-bolder text-danger"
                            style="cursor: pointer !important"
                            v-if="loanApplication?.parent_loan != null"
                          >
                            <strong>{{
                              loanApplication?.parent_loan?.application_id
                            }}</strong>
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">CIN Number</h5>
                        </div>
                        <div
                          class="col-lg-7 d-flex flex-row align-content-center"
                        >
                          <h5 class="text-bolder">
                            <a
                              style="text-decoration: underline; color: #005da9"
                              id="customer-btn"
                              target="_blank"
                              :href="'/customer-detail/' + user_uuid"
                              >{{ loanApplication?.user?.cin_number }}</a
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Applicant Name</h5>
                        </div>
                        <div
                          class="col-lg-7 d-flex flex-row align-content-center"
                        >
                          <h5 class="text-bolder">
                            {{ loanApplication.name }}
                          </h5>
                          <!-- <i
                            class="fa fa-edit mx-3 pt-1"
                            style="cursor: pointer"
                            @click="openResetLoanDetail()"
                          ></i> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Mobile Number</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{ loanApplication.mobile_number }}
                          </h5>
                          <!-- <i
                            class="fa fa-edit mx-3 pt-1"
                            style="cursor: pointer"
                            @click="openResetLoanDetail()"
                          ></i> -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Alt Mobile Number 1</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{ loanApplication.alt_mobile_number_1 }}
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Alt Mobile Number 2</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{ loanApplication.alt_mobile_number_2 }}
                          </h5>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Permanent Address</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{
                              loanApplication?.home_address?.address
                                ? loanApplication?.home_address?.address
                                : "-"
                            }}
                            {{
                              loanApplication?.home_address?.city
                                ? "," +
                                  loanApplication?.home_address?.city +
                                  ","
                                : "-"
                            }}
                            {{
                              loanApplication?.home_address?.state?.name
                                ? loanApplication?.home_address?.state?.name
                                : "-"
                            }}
                          </h5>
                          <i
                            v-if="access.can_update == 1"
                            class="fa fa-edit mx-3 pt-1"
                            title="Permanent Address"
                            data-toggle="tooltip"
                            data-placement="right"
                            style="cursor: pointer"
                            @click="onUpdateAddress('HOME_ADDRESS')"
                          ></i>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Residential Address</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{
                              loanApplication?.residential_address?.address
                                ? loanApplication?.residential_address?.address
                                : "-"
                            }}
                            {{
                              loanApplication?.residential_address?.city
                                ? "," +
                                  loanApplication?.residential_address?.city +
                                  ","
                                : "- "
                            }}{{
                              loanApplication?.residential_address?.state?.name
                                ? loanApplication?.residential_address?.state
                                    ?.name
                                : "-"
                            }}
                          </h5>
                          <i
                            v-if="access.can_update == 1"
                            class="fa fa-edit mx-3 pt-1"
                            title="Residential Address"
                            style="cursor: pointer"
                            data-toggle="tooltip"
                            data-placement="right"
                            @click="onUpdateAddress('RESIDENTIAL_ADDRESS')"
                          ></i>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <h5 class="label-text">Office Address</h5>
                        </div>
                        <div class="col-lg-7 d-flex flex-row">
                          <h5 class="text-bolder">
                            {{
                              loanApplication?.office_address?.address
                                ? loanApplication?.office_address?.address
                                : "-"
                            }}
                            {{
                              loanApplication?.office_address?.city
                                ? "," +
                                  loanApplication?.office_address?.city +
                                  ","
                                : "- "
                            }}
                            {{
                              loanApplication?.office_address?.state?.name
                                ? loanApplication?.office_address?.state?.name
                                : "-"
                            }}
                          </h5>
                          <i
                            v-if="access.can_update == 1"
                            class="fa fa-edit mx-3 pt-1"
                            title="Office Address"
                            style="cursor: pointer"
                            data-toggle="tooltip"
                            data-placement="right"
                            @click="onUpdateAddress('OFFICE_ADDRESS')"
                          ></i>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                          <a
                            v-if="access.can_update == 1"
                            title="Permanent Address"
                            class="btn btn-brand-02 mx-1"
                            style="cursor: pointer"
                            @click="onUpdateAddress('HOME_ADDRESS')"
                            ><img src="/static/img/edit-icon.svg" alt="" />
                            Permanent Address</a
                          >
                        </div>
                        <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                          <a
                            v-if="access.can_update == 1"
                            title="Residential Address"
                            class="btn btn-brand-02 mx-1"
                            style="cursor: pointer"
                            @click="onUpdateAddress('RESIDENTIAL_ADDRESS')"
                            ><img src="/static/img/edit-icon.svg" alt="" />
                            Residential Address</a
                          >
                        </div>
                        <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                          <a
                            v-if="access.can_update == 1"
                            title="Office Address"
                            class="btn btn-brand-02 mx-2"
                            style="cursor: pointer"
                            @click="onUpdateAddress('OFFICE_ADDRESS')"
                            ><img src="/static/img/edit-icon.svg" alt="" />
                            Office Address</a
                          >
                        </div>
                      </div> -->
                      <div class="row mt-5">
                        <div class="col-lg-4">
                          <h5 class="text-bolder">Photo</h5>
                          <button
                            @click="OpenPhotoModal('APPLICATION')"
                            class="btn btn-brand-02"
                            :disabled="!loanApplication.application_id"
                          >
                            <span v-if="loanApplication.applicant_photo"
                              >Re-Take</span
                            >
                            <span v-else>Take Photo</span>
                          </button>
                          <img
                            :src="loanApplication.applicant_photo"
                            id="img_hoder_2"
                            class="img-fluid ml-3"
                            v-if="loanApplication.applicant_photo"
                            style="
                              width: 120px;
                              height: 120px;
                              object-fit: contain;
                            "
                          />
                        </div>
                        <div class="col-lg-4">
                          <h5 class="text-bolder">Signature</h5>
                          <button
                            @click="OpenScanModal('APPLICATION')"
                            class="btn btn-brand-02"
                            :disabled="!loanApplication.application_id"
                          >
                            <span v-if="loanApplication.applicant_thumb_img"
                              >Re-Scan</span
                            >
                            <span v-else>Click to Scan</span>
                          </button>
                          <img
                            :src="loanApplication.applicant_thumb_img"
                            id="img_hoder_1"
                            class="img-fluid ml-3"
                            v-if="loanApplication.applicant_thumb_img"
                            style="
                              width: 120px;
                              height: 120px;
                              object-fit: contain;
                            "
                          />
                        </div>
                        <div class="col-lg-4 d-flex flex-row">
                          <div class="">
                            <h5 class="text-bolder">Video</h5>

                            <button
                              href="javascript:void(0)"
                              class="btn btn-brand-02"
                              id="upload-applicant-video"
                              @click="OpenUploadApplicantVideo($event)"
                            >
                              {{
                                applicant_video == null ? "Upload" : "Re-Upload"
                              }}
                            </button>
                          </div>
                          <div class="">
                            <div
                              v-if="applicant_video != null"
                              class="text-center"
                            >
                              <video
                                controls
                                id="video-tag"
                                style="
                                  width: 80% !important;
                                  height: 153px;
                                  border-radius: 10px;
                                  object-fit: contain;
                                "
                              >
                                <source
                                  style="
                                    width: 80% !important;
                                    height: 153px;
                                    border-radius: 10px;
                                    object-fit: contain;
                                  "
                                  id="video-source"
                                  :src="applicant_video"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <div class="mb-3">
                              <input
                                class="d-none"
                                type="file"
                                id="uploadApplicantVideo"
                                accept="video/*"
                                @change="onUploadApplicantVideo($event)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3"
                    >
                      <h4 class="text-bolder">Loan Requirement</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-4 d-flex flex-row">
                          <h5 class="label-text">Requested Loan Amount :</h5>
                          <h5 class="text-bolder mx-3">
                            {{
                              $helperService.getFormattedCurrency(
                                requestedLoanAmont
                              )
                            }}
                          </h5>
                        </div>
                        <div class="col-lg-4 d-flex flex-row">
                          <h5 class="label-text">
                            In-Principle Approval Amount :
                          </h5>
                          <h5 class="text-bolder mx-3">
                            {{
                              $helperService.getFormattedCurrency(
                                sanctionAmount
                              )
                            }}
                          </h5>
                        </div>
                        <div class="col-lg-4 d-flex flex-row">
                          <h5 class="label-text">Max Loan Approved Amount :</h5>
                          <h5 class="text-bolder mx-3">
                            {{
                              $helperService.getFormattedCurrency(
                                max_approved_amount
                              )
                            }}
                          </h5>
                        </div>
                        <div
                          class="col-lg-4 d-flex flex-row"
                          v-if="
                            userType !== 'SUPER_ADMIN' &&
                            userType !== 'PARTNER' &&
                            userType !== 'BRANCH_STAFF' &&
                            userType !== 'ENQUIRY_USER' &&
                            userType !== 'DOCUMENT_VERIFY_USER'
                          "
                        >
                          <input
                            class="mx-2"
                            id="flexCheckChecked"
                            type="checkbox"
                            v-bind:true-value="1"
                            v-bind:false-value="0"
                            @change="changeLoanType($event)"
                            v-model="change_loan_type"
                          />
                          <label
                            class="form-check-label pt-1"
                            for="flexCheckChecked"
                          >
                            <u>Change Loan Type </u>
                          </label>
                        </div>
                      </div>
                      <Form @submit="save">
                        <div class="row pt-3">
                          <div
                            :class="
                              has_change_narration === true
                                ? 'col-lg-3 col-sm-3'
                                : 'col-lg-3 col-sm-3'
                            "
                          >
                            <label for="loan_amount"
                              >Final Disburse Amount</label
                            >
                            <Field
                              :disabled="
                                userType == 'BRANCH_STAFF' &&
                                userType !== 'ENQUIRY_USER' &&
                                userType !== 'DOCUMENT_VERIFY_USER'
                                  ? true
                                  : false
                              "
                              name="loan_amount"
                              id="loan_amount"
                              class="form-control"
                              :rules="
                                application_status == 'PENDING_FOR_VERIFICATION'
                                  ? 'required:' +
                                    'sanction amount' +
                                    '|numeric|multiply'
                                  : 'required:' +
                                    'final disburse amount' +
                                    '|numeric|multiply'
                              "
                              :validateOnInput="true"
                              v-model="loan_disburment_amount"
                              type="text"
                              :placeholder="
                                application_status == 'PENDING_FOR_VERIFICATION'
                                  ? 'Enter Sanction Amount'
                                  : 'Enter Final Disburse Amount'
                              "
                              autocapitalize="false"
                            />
                            <ErrorMessage
                              name="loan_amount"
                              class="validation-msg"
                            />
                          </div>
                          <div
                            :class="
                              has_change_narration === true
                                ? 'col-lg-3 col-sm-3'
                                : 'col-lg-3 col-sm-3'
                            "
                          >
                            <label for="loan_amount">Loan Type</label>
                            <Field
                              v-slot="{ feild }"
                              name="loan_type"
                              rules="required:loan type,true"
                              :validateOnInput="true"
                              v-model="loan_type_id"
                              :disabled="
                                userType == 'BRANCH_STAFF' &&
                                userType !== 'ENQUIRY_USER' &&
                                userType !== 'DOCUMENT_VERIFY_USER'
                                  ? false
                                  : true
                              "
                            >
                              <Multiselect
                                v-bind="feild"
                                :disabled="
                                  userType == 'BRANCH_STAFF' &&
                                  userType !== 'ENQUIRY_USER' &&
                                  userType !== 'DOCUMENT_VERIFY_USER'
                                    ? true
                                    : false
                                "
                                ref="loan_type"
                                mode="single"
                                trackBy="title"
                                label="title"
                                valueProp="value"
                                placeholder="Select Loan Type"
                                class="form-control"
                                v-model="loan_type_id"
                                :options="loanTypeList"
                              />
                            </Field>
                            <ErrorMessage
                              name="loan_type"
                              class="validation-msg"
                            />
                          </div>
                          <div
                            v-show="application_status == 'DOCUMENT_VERIFIED'"
                            :class="
                              has_change_narration === true
                                ? 'col-lg-3 col-sm-3'
                                : 'col-lg-3 col-sm-3'
                            "
                          >
                            <label for="sanction_notes">Note </label>
                            <span class="text-danger">*</span>
                            <Field
                              name="sanction_notes"
                              id="sanction_notes"
                              class="form-control"
                              :validateOnInput="true"
                              v-model="sanction_notes"
                              :disabled="
                                userType != 'BRANCH_STAFF' &&
                                userType == 'ENQUIRY_USER' &&
                                userType == 'DOCUMENT_VERIFY_USER'
                                  ? true
                                  : false
                              "
                              :rules="
                                application_status == 'DOCUMENT_VERIFIED'
                                  ? 'required:' + 'sanction notes'
                                  : ''
                              "
                              type="text"
                              :placeholder="'Enter notes'"
                              autocapitalize="false"
                            />
                            <ErrorMessage
                              name="sanction_notes"
                              class="validation-msg"
                            />
                          </div>
                          <div
                            :class="
                              has_change_narration === true
                                ? 'col-lg-3 col-sm-3'
                                : 'col-lg-3 col-sm-3'
                            "
                            v-show="has_change_narration === true"
                          >
                            <label for="remark"
                              >Remark <span class="text-danger">*</span></label
                            >
                            <Field
                              name="remark"
                              id="remark"
                              class="form-control"
                              :rules="
                                has_change_narration === true
                                  ? 'required:loan type change remark'
                                  : ''
                              "
                              :validateOnInput="true"
                              v-model="change_narration"
                              type="text"
                              :placeholder="'Enter Loan Type Change Remark'"
                              autocapitalize="false"
                            />
                            <ErrorMessage
                              name="remark"
                              class="validation-msg"
                            />
                          </div>
                          <div
                            :class="
                              has_change_narration === true
                                ? 'col-lg-2 col-sm-2 pt-4'
                                : 'col-lg-2 col-sm-2 pt-4'
                            "
                          >
                            <button
                              v-if="
                                access.can_update == 1 &&
                                userType != 'BRANCH_STAFF' &&
                                userType != 'ENQUIRY_USER' &&
                                userType != 'DOCUMENT_VERIFY_USER'
                              "
                              type="submit"
                              class="btn btn-brand-01 wb-save-btn"
                              id="loan-application-btn"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Form>
                      <div class="row pt-3">
                        <div
                          class="col-lg-4 d-flex flex-row"
                          v-if="
                            (userType === 'SUPER_ADMIN' ||
                              userType === 'PARTNER') &&
                            change_narration !== null
                          "
                        >
                          <h6 class="bolder">Loan Type Change Remark :</h6>
                          <h5 class="text-bolder mx-3">
                            {{ change_narration }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- KYC Details -->
                <!-- <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3"
                    >
                      <div class="row d-flex flex-row justify-content-between">
                        <div class="col-lg-6">
                          <h4 class="text-bolder">KYC Details</h4>
                        </div>
                        <div
                          class="col-lg-6 text-right flex-row"
                          v-if="access.can_update == 1"
                        >
                          <a
                            href="javascript:void(0)"
                            @click="onUplaodKycDocument"
                          >
                            + Upload</a
                          >
                          <button
                            v-if="isShowApproveBtn"
                            type="button"
                            class="btn btn-brand-01 wb-cancel-btn mx-3"
                            id="status-btn"
                            @click="onVerify()"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="table-responsive">
                            <table class="table table-primary mg-b-0">
                              <thead>
                                <tr>
                                  <th scope="col"  v-if="kycLoanDocuments.length > 0 && userType !== 'ENQUIRY_USER'">
                                      <input
                                        type="checkbox"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        @change="selectAll($event)"
                                        id="create"
                                        v-model="select_all"
                                      />
                                  </th>
                                  <th scope="col">Document</th>
                                  <th scope="col">Document No.</th>
                                  <th scope="col">Updated By</th>
                                  <th scope="col">Updated At</th>
                                  <th scope="col"  v-show="userType !== 'ENQUIRY_USER'">Approved By</th>
                                  <th scope="col"  v-show="userType !== 'ENQUIRY_USER'">Approved At</th>
                                  <th scope="col" class="">Action</th>
                                </tr>
                              </thead>
                              <tbody v-if="kycLoanDocuments.length > 0">
                                <tr
                                  v-for="(item, i) in kycLoanDocuments"
                                  :key="item.id"
                                >
                                  <td class="" v-if="userType !== 'ENQUIRY_USER'">
                                    <input
                                      type="checkbox"
                                      v-bind:true-value="1"
                                      v-bind:false-value="0"
                                      class="'custom-control-input'"
                                      @change="checkPermission($event, item)"
                                      :id="'create_' + i"
                                      v-model="item.is_checked"
                                    />
                                  </td>
                                  <td>{{ item?.title }}</td>
                                  <td>{{ item?.doc_no }}</td>
                                  <td>
                                    {{
                                      item?.added_by?.name
                                        ? item?.added_by?.name
                                        : "-"
                                    }}
                                  </td>
                                  <td>
                                    <span>
                                      {{
                                        $helperService.getFormattedDate(
                                          item?.updated_at
                                        )
                                      }}</span
                                    >
                                  </td>
                                  <td v-show="userType !== 'ENQUIRY_USER'">
                                    {{
                                      item?.added_by?.name
                                        ? item?.added_by?.name
                                        : "-"
                                    }}
                                  </td>
                                  <td  v-show="userType !== 'ENQUIRY_USER'">
                                    <span>
                                      {{
                                        $helperService.getFormattedDate(
                                          item?.updated_at
                                        )
                                      }}</span
                                    >
                                  </td>

                                  <td style="cursor: pointer">
                                    <img
                                      v-if="
                                        item.doc_img != null ||
                                        item.doc_img == ''
                                      "
                                      :src="
                                        item.doc_img
                                          ? item.doc_img
                                          : '/static/img/placeholder.png'
                                      "
                                      class="img-rounded"
                                      style="width: 50px"
                                      alt=""
                                      v-on:click="openImage(item.doc_img, i)"
                                    />

                                    <a
                                      href="javascript:void(0)"
                                      v-if="item.doc_img != null"
                                      @click="
                                        downloadPdf(item.doc_img, item.title)
                                      "
                                      class="mx-2"
                                      >Download</a
                                    >
                                    <a
                                      href="javascript:void(0)"
                                      @click="editKycDocument(item, 'EDIT')"
                                      class="mx-2"
                                      >Edit</a
                                    >
                                    <a
                                      title="Delete"
                                      class="mx-2"
                                      style="cursor: pointer"
                                      @click="openDeleteModal(item)"
                                      ><img
                                        src="/static/img/delete-icon.svg"
                                        alt=""
                                    /></a>
                                  </td>
                                </tr>
                              </tbody>
                              <template v-else>
                                <tr>
                                  <td :colspan="userType !== 'ENQUIRY_USER' ? 8 : 5">
                                    <div class="text-center m-5">
                                      <h3>Sorry,record not found.</h3>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3"
                    >
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="text-bolder">KYC Details</h4>
                        </div>
                        <div
                          class="col-lg-6 text-right"
                          v-if="access.can_update == 1"
                        >
                          <a
                            href="javascript:void(0)"
                            @click="onUplaodKycDocument"
                          >
                            + Upload</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="table-responsive">
                            <table class="table table-primary mg-b-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    v-if="
                                      kycLoanDocuments.length > 0 &&
                                      userType !== 'ENQUIRY_USER'
                                    "
                                  ></th>
                                  <th scope="col">Document</th>
                                  <th scope="col">Document No.</th>
                                  <th scope="col">Updated By</th>
                                  <th scope="col">Updated At</th>
                                  <th
                                    scope="col"
                                    v-show="userType !== 'ENQUIRY_USER'"
                                  >
                                    Approved By
                                  </th>
                                  <th
                                    scope="col"
                                    v-show="userType !== 'ENQUIRY_USER'"
                                  >
                                    Approved At
                                  </th>
                                  <th scope="col" class="">Action</th>
                                </tr>
                              </thead>
                              <tbody v-if="kycLoanDocuments.length > 0">
                                <tr
                                  v-for="(item, i) in kycLoanDocuments"
                                  :key="item.id"
                                >
                                  <td
                                    class=""
                                    v-if="userType !== 'ENQUIRY_USER'"
                                  >
                                    <input
                                      type="checkbox"
                                      v-bind:true-value="1"
                                      v-bind:false-value="0"
                                      class="'custom-control-input'"
                                      @change="openConfirmation($event, item)"
                                      :id="'create_' + i"
                                      v-model="item.has_checked"
                                    />
                                  </td>
                                  <td>{{ item?.title }}</td>
                                  <td>{{ item?.doc_no }}</td>
                                  <td>
                                    {{
                                      item?.added_by?.name
                                        ? item?.added_by?.name
                                        : "-"
                                    }}
                                  </td>
                                  <td>
                                    <span>
                                      {{
                                        $helperService.getFormattedDate(
                                          item?.updated_at
                                        )
                                      }}</span
                                    >
                                  </td>
                                  <td v-show="userType !== 'ENQUIRY_USER'">
                                    {{
                                      item?.approved_by_user?.name
                                        ? item?.approved_by_user?.name
                                        : "-"
                                    }}
                                  </td>
                                  <td v-show="userType !== 'ENQUIRY_USER'">
                                    <span>
                                      {{
                                        $helperService.getFormattedDate(
                                          item?.approved_at
                                        )
                                      }}</span
                                    >
                                  </td>
                                  <td style="cursor: pointer">
                                    <img
                                      v-if="
                                        item.doc_img != null ||
                                        item.doc_img == ''
                                      "
                                      :src="
                                        item.doc_img
                                          ? item.doc_img
                                          : '/static/img/placeholder.png'
                                      "
                                      class="img-rounded"
                                      style="width: 50px"
                                      alt=""
                                      v-on:click="openImage(item.doc_img, i)"
                                    />

                                    <a
                                      href="javascript:void(0)"
                                      v-if="item.doc_img != null"
                                      @click="
                                        downloadPdf(item.doc_img, item.title)
                                      "
                                      class="mx-2"
                                      >Download</a
                                    >
                                    <a
                                      href="javascript:void(0)"
                                      @click="editKycDocument(item, 'EDIT')"
                                      class="mx-2"
                                      >Edit</a
                                    >
                                    <a
                                      title="Delete"
                                      class="mx-2"
                                      style="cursor: pointer"
                                      @click="openDeleteModal(item)"
                                      ><img
                                        src="/static/img/delete-icon.svg"
                                        alt=""
                                    /></a>
                                  </td>
                                </tr>
                              </tbody>
                              <template v-else>
                                <tr>
                                  <td
                                    :colspan="
                                      userType !== 'ENQUIRY_USER' ? 8 : 5
                                    "
                                  >
                                    <div class="text-center m-5">
                                      <h3>Sorry,record not found.</h3>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Loan Documents Details -->
                <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3"
                    >
                      <div class="row">
                        <div class="col-lg-6">
                          <h4 class="text-bolder">Loan Documents</h4>
                        </div>
                        <div
                          class="col-lg-6 text-right"
                          v-if="access.can_update == 1"
                        >
                          <a
                            href="javascript:void(0)"
                            @click="onUplaodDocument"
                          >
                            + Upload</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="table-responsive">
                            <table class="table table-primary mg-b-0">
                              <thead>
                                <tr>
                                  <th scope="col">Document</th>
                                  <th scope="col">Updated By</th>
                                  <th scope="col">Updated At</th>
                                  <th scope="col" class="">Action</th>
                                </tr>
                              </thead>
                              <tbody v-if="documents.length > 0">
                                <tr
                                  v-for="(item, i) in documents"
                                  :key="item.id"
                                >
                                  <td>{{ item?.title }}</td>
                                  <td>
                                    {{
                                      item?.added_by?.name
                                        ? item?.added_by?.name
                                        : "-"
                                    }}
                                  </td>
                                  <td>
                                    <span>
                                      {{
                                        $helperService.getFormattedDate(
                                          item?.updated_at
                                        )
                                      }}</span
                                    >
                                  </td>

                                  <td
                                    v-if="
                                      item.image != null || item.image == ''
                                    "
                                    style="cursor: pointer"
                                  >
                                    <img
                                      :src="
                                        item.image
                                          ? item.image
                                          : '/static/img/placeholder.png'
                                      "
                                      class="img-rounded"
                                      style="width: 50px"
                                      alt=""
                                      v-on:click="openImage(item.image, i)"
                                    />

                                    <a
                                      href="javascript:void(0)"
                                      @click="
                                        downloadPdf(item.image, item.title)
                                      "
                                      class="mx-2"
                                      >Download</a
                                    >
                                  </td>
                                  <td v-else>-</td>
                                </tr>
                              </tbody>
                              <template v-else>
                                <tr>
                                  <td colspan="4">
                                    <div class="text-center m-5">
                                      <h3>Sorry,record not found.</h3>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Verification Checklist -->
                <div
                  class="col-lg-12 pt-3"
                  v-if="checklist.length > 0 && application_status != 'PENDING'"
                >
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div
                      style="border-bottom: 1px solid #ccc"
                      class="card-header pb-2 pt-3"
                    >
                      <h4 class="text-bolder">Verification Checklist</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive" style="min-height: auto">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th>Verification List</th>
                              <th>Verified By</th>
                              <th>Verified at</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="item in checklist" :key="item.id">
                              <tr v-if="item.doc_type == 'CHECKLIST'">
                                <td>
                                  <div class="form-check">
                                    <input
                                      :disabled="
                                        application_status !=
                                        'PENDING_FOR_VERIFICATION'
                                      "
                                      class="form-check-input"
                                      @change="checkPermission($event, item)"
                                      v-model="item.has_checked"
                                      true-value="1"
                                      false-value="0"
                                      type="checkbox"
                                      value=""
                                      :id="item.id"
                                    />
                                    <label
                                      class="form-check-label"
                                      :for="item.id"
                                    >
                                      {{ item.title }}
                                    </label>
                                  </div>
                                </td>

                                <td v-if="item.added_by != null">
                                  {{ item?.added_by?.name }}
                                </td>
                                <td v-else>-</td>
                                <td v-if="item.updated_at != null">
                                  <span>
                                    {{
                                      $helperService.getFormattedDate(
                                        item?.updated_at
                                      )
                                    }}</span
                                  >
                                </td>
                                <td v-else>-</td>
                                <td
                                  v-if="
                                    item.type == 'LOCATION_PIN' &&
                                    item.latitude != null &&
                                    item.longitude != null
                                  "
                                >
                                  <a
                                    title="View Map"
                                    class="mx-2"
                                    style="cursor: pointer"
                                    @click="OpenMapModel(item)"
                                    ><img
                                      src="/static/img/location.png"
                                      alt=""
                                      style="width: 22px !important"
                                  /></a>
                                </td>
                                <td v-else>-</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 pt-3">
                  <div class="card rounded-2" style="border-radius: 10px">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6">
                          <h6><b>Assign to Verification:</b></h6>
                          <div>
                            {{ loanApplication?.verification_officer?.name }}
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <h6><b>Mobile Number:</b></h6>
                          <div>
                            {{
                              loanApplication?.verification_officer
                                ?.mobile_number
                            }}
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <h6><b>Sanction comment:</b></h6>
                          <div>{{ sanctionNarration }}</div>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6"
                          v-if="loanApplication?.verified_by != null"
                        >
                          <div><b>Verified At:</b></div>
                          <div>
                            {{
                              $helperService.getFormattedDate(
                                loanApplication?.verified_by?.completed_at
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row pt-3" v-if="loanApplication?.verified_by != null">
                          <div class="col-lg-4 col-sm-6">
                            <h6><b>Verified By :</b></h6>
                            <div>{{ loanApplication?.verified_by?.name }}</div>
                          </div>

                          <div class="col-lg-4 col-sm-6">
                            <div><b>Verified At:</b></div>
                            <div>{{
                              $helperService.getFormattedDate(loanApplication?.verified_by?.completed_at)
                            }}</div>
                          </div>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-5 mb-3"
                  v-if="
                    application_status !== 'PENDING_FOR_FINAL_APPROVAL' &&
                    access.can_update == 1
                  "
                >
                  <div
                    class="col-12 text-center"
                    v-if="hasFinalApprovalPermission === true"
                  >
                    <!-- <button type="submit" id="save-btn"
                      class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                      Save
                    </button> -->
                    <button
                      type="button"
                      id="final-btn"
                      class="btn btn-brand-01 wb-save-btn"
                      @click="finalApproval"
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      @click="onRejectModal()"
                      class="btn btn-danger wb-cancel-btn mx-2"
                    >
                      Reject
                    </button>
                  </div>
                </div>
                <div
                  class="col-lg-12 pt-3 text-center"
                  v-if="
                    application_status == 'PENDING_FOR_FINAL_APPROVAL' &&
                    access.can_update == 1
                  "
                >
                  <div class="row">
                    <div class="col-lg-12 d-flex justify-content-center">
                      <button
                        id="final_approve_btn"
                        type="button"
                        @click="onSendFinalApprove()"
                        class="btn btn-brand-01 wb-save-btn"
                      >
                        Send for Final Approve
                      </button>
                      <button
                        type="button"
                        @click="onRejectModal()"
                        class="btn btn-danger wb-cancel-btn mx-2"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tab_2"
                role="tabpanel"
                aria-labelledby="tab-2"
                class="tab-pane fade"
              >
                <div class="card rounded-5">
                  <div class="card-body p-3 p-lg-3">
                    <div class="row">
                      <div class="col-12 text-right">
                        <a @click="addGuarantor()" class="btn btn-brand-02"
                          >+ Add
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div class="table-responsive" style="min-height: auto">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th>Guarantor Name</th>
                            <th>Guarantor No.</th>
                            <th>Photo</th>
                            <th>Signature</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="guarantorList.length > 0">
                          <tr
                            v-for="guarantor in guarantorList"
                            :key="guarantor.id"
                          >
                            <td>{{ guarantor.guarantor_name }}</td>
                            <td>
                              +91 {{ guarantor.mobile_number }}
                              {{
                                guarantor.alt_mobile_number_1
                                  ? ", " + guarantor.alt_mobile_number_1
                                  : ""
                              }}
                              {{
                                guarantor.alt_mobile_number_2
                                  ? ", " + guarantor.alt_mobile_number_2
                                  : ""
                              }}
                            </td>
                            <td>
                              <img
                                :src="guarantor.guarantor_photo"
                                class="img-fluid"
                                width="80"
                                height="80"
                                v-if="guarantor.guarantor_photo"
                              />
                            </td>
                            <td>
                              <img
                                :src="guarantor.guarantor_thumb_img"
                                class="img-fluid"
                                width="80"
                                height="80"
                                v-if="guarantor.guarantor_thumb_img"
                              />
                            </td>
                            <td>
                              <a
                                title="Edit"
                                class="mx-2"
                                style="cursor: pointer"
                                @click="editGuarantor(guarantor)"
                                ><img src="/static/img/edit-icon.svg" alt=""
                              /></a>
                              <a
                                title="View"
                                class="mx-2"
                                style="cursor: pointer"
                                @click="onDeleteEvent(guarantor)"
                                ><img src="/static/img/delete-icon.svg" alt=""
                              /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <template v-if="guarantorList.length == 0">
                        <div class="text-center m-5">
                          <h3>Sorry,record not found.</h3>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View Modal -->
  <div
    class="modal fade"
    id="viewModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <img :src="url" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Upload KYC Document Modal -->
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadLoanDocument"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadLoanDocument"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadLoanDocument"
          class="columns column is-multiline is-12"
          ref="onUploadLoanDocument"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="loan_title"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="title"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="loan_title"
                    :options="documentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4" v-if="loan_title == 'Other'">
                <div class="form-group">
                  <label
                    >Document Title
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="loan_title"
                    v-model="document_narration"
                    class="form-control"
                    rules="required:document title"
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document Title"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="loan_title" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document Type
                    <span class="text-danger">*</span>
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectFile($event)"
                  >
                    <Field
                      name="upload_file"
                      v-model="fileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectFile"
                      name="upload_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_file" class="validation-msg" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="update-password"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseUplaodKycModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="kycDocuments.doc_id"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="uuid"
                    @select="afterValueChange"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="kycDocuments.doc_id"
                    :options="kycDocumentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>
                    Document Number
                    <span class="text-danger" v-if="is_optional == 0">*</span>
                  </label>
                  <Field
                    name="doc_number"
                    v-model="kycDocuments.doc_no"
                    class="form-control"
                    :rules="
                      is_optional == 0
                        ? documentTitle == 'AADHAAR_CARD'
                          ? 'required:document number,true|aadhaar'
                          : documentTitle == 'PAN_CARD'
                          ? 'required:document number,true|PAN'
                          : ''
                        : ''
                    "
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document number"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="doc_number" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Issue Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="issue_date"
                    class="form-control"
                    v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:issue date,true' : ''"
                    id="issue_date"
                    v-model="kycDocuments.issue_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Issue Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.issue_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>

                  <ErrorMessage name="issue_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Expiry Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="expired_date"
                    class="form-control"
                    v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:expiry date,true' : ''"
                    id="expired_date"
                    v-model="kycDocuments.expiry_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Expired Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.expiry_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="expired_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectKycFile($event)"
                  >
                    <Field
                      name="upload_kyc_file"
                      v-model="kycFileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectKycFile"
                      name="upload_kyc_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onKycDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectKycFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_kyc_file" class="validation-msg" />
                </div>
                <a
                  v-if="downloadKycDocument != null"
                  style="cursor: pointer"
                  @click="downloadPdf(downloadKycDocument, 'kyc Document')"
                  ><img
                    src="/static/img/download-icon.svg"
                    class="mx-3"
                    alt=""
                  />Download</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="onCloseUplaodKycModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="loan-kyc-document"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="thumbModal"
    tabindex="-1"
    aria-labelledby="thumbModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Signature Update</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <div class="row">
                <div class="col-lg-5">
                  <div class="mb-3">
                    <h5 class="text-bolder">
                      <a
                        @click="addLoanApplication('APPLICATION')"
                        :disabled="scanLoader"
                        class="btn btn-brand-02"
                      >
                        <span v-if="applicant_thumb_img">Re-Scan</span>
                        <span v-else>Click to Scan </span
                        ><span v-if="scanLoader" class="mx-2 spinner"></span>
                      </a>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mt-3">
                    <h5 class="text-center">OR</h5>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="mb-3">
                    <input
                      class="d-none"
                      type="file"
                      id="AddPhoto"
                      accept="image/*"
                      @change="onChange($event)"
                    />
                    <h6 class="text-primary text-center">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-brand-02"
                        v-if="showSignatureView"
                        @click="OpenSelectImage($event)"
                        >Select Signature</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="btn btn-brand-02"
                        v-else
                        @click="uploadThumb()"
                        >Upload Signature</a
                      >
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-center">
                  <div class="mb-3">
                    <h5 class="text-bolder">
                      <img
                        id="img_hoder_4"
                        v-if="showSignatureView && applicant_thumb_img"
                        :src="applicant_thumb_img"
                        class="img-fluid"
                        width="100"
                        height="50"
                      />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            type="button"
            @click="this.applicant_thumb_img = ''"
            v-if="applicant_thumb_img"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Remove
          </button>
          <button
            id="thumb-btn"
            type="button"
            data-dismiss="modal"
            v-else
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-01 wb-save-btn"
            v-if="applicant_thumb_img"
            id="thumb-scan"
            @click="saveThumb()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="photoModal"
    tabindex="-1"
    aria-labelledby="photoModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Photo</h5>
          <button
            type="button"
            class="close"
            @click="onCloseSavePhoto()"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <div class="row">
                <div class="col-lg-5">
                  <div class="mb-3">
                    <button
                      id="opencamera"
                      @click="openCam()"
                      class="btn btn-brand-02 mb-3"
                    >
                      Open Camera
                    </button>
                    <button
                      id="startbutton"
                      @click="startup()"
                      class="btn btn-brand-02 mb-3"
                    >
                      Take Photo
                    </button>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mt-3">
                    <h5 class="text-center">OR</h5>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="mb-3">
                    <input
                      class="d-none"
                      type="file"
                      id="TakePhotos"
                      accept="image/*"
                      @change="onTakePhoto($event)"
                    />
                    <h6 class="text-primary text-center">
                      <a
                        href="javascript:void(0)"
                        @click="OpenTakeSelectImage($event)"
                        class="btn btn-brand-02 mb-3"
                        >Select Photo</a
                      >
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 text-center">
                  <h5 class="text-bolder">
                    <div class="camera">
                      <video class="d-none" id="video">
                        Video stream not available.
                      </video>
                    </div>
                  </h5>
                  <canvas id="canvas" class="d-none"> </canvas>
                  <h5 class="text-bolder">
                    <img
                      id="img_hoder_3"
                      v-if="showUploadImage"
                      :src="applicant_photo"
                      class="img-fluid"
                      width="100"
                      height="50"
                    />
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            type="button"
            @click="onRemoveApplicantPhoto()"
            v-if="applicant_photo"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Clear
          </button>
          <button
            id="cancel-btn"
            type="button"
            @click="onCloseSavePhoto()"
            v-else
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-01 wb-save-btn"
            v-if="applicant_photo"
            id="image-upload-btn"
            @click="savePhoto()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="openGooglemap"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openGooglemap"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-md"
      role="document"
    >
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeMapModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Map</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <div class="row">
            <div class="col-lg-12">
              <iframe
                :src="
                  'https://www.google.com/maps?q=' +
                  maps?.latitude +
                  ',' +
                  maps?.longitude +
                  '&hl=en&z=14&amp;output=embed'
                "
                style="width: 100%; height: 500px !important"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="closeMapModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="viewAddressModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="addressType == 'HOME_ADDRESS'">
            Permanent Address
          </h5>
          <h5 class="modal-title" v-if="addressType == 'OFFICE_ADDRESS'">
            Office Address
          </h5>
          <h5 class="modal-title" v-if="addressType == 'RESIDENTIAL_ADDRESS'">
            Residential Address
          </h5>
          <button
            type="button"
            class="close"
            @click="onCloseAddress()"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <Form
          @submit="onUpdateAddresses"
          class="columns column"
          ref="addressUpdate"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div
                    class="col-sm-12 mb-3 pt-2"
                    v-if="
                      addressType == 'RESIDENTIAL_ADDRESS' ||
                      addressType == 'HOME_ADDRESS'
                    "
                  >
                    <label> Address Type </label>
                    <div class="">
                      <span class="mr-3">
                        <input
                          class=""
                          v-model="addresses.has_owned"
                          :checked="addresses.has_owned == 0 ? true : false"
                          type="radio"
                          value="0"
                          id="Rented"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Rented">
                          Rented
                        </label>
                      </span>
                      <span>
                        <input
                          class=""
                          v-model="addresses.has_owned"
                          :checked="addresses.has_owned == 1 ? true : false"
                          type="radio"
                          value="1"
                          id="Owned"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Owned">
                          Owned
                        </label>
                      </span>
                    </div>

                    <ErrorMessage name="gender" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="address"
                      id="address"
                      rules="required:address"
                      class="form-control"
                      v-model="addresses.address"
                      type="text"
                      v-bind:placeholder="'Address'"
                    />
                    <ErrorMessage name="address" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      City
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="city"
                      id="city"
                      rules="required:city"
                      class="form-control"
                      v-model="addresses.city"
                      type="text"
                      v-bind:placeholder="'City'"
                    />
                    <ErrorMessage name="city" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      State
                      <span class="text-danger">*</span>
                    </label>

                    <Field
                      v-slot="{ field }"
                      name="state"
                      rules="required:state list,true"
                      :validateOnInput="true"
                      v-model="addresses.state_id"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:state,true"
                        ref="state_multiselect"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="id"
                        placeholder="Select State"
                        class="form-control"
                        searchable="true"
                        v-model="addresses.state_id"
                        :options="stateList"
                      />
                    </Field>
                    <ErrorMessage name="state" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      Pincode
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="pincode"
                      id="pincode"
                      rules="required:pincode|pincode"
                      class="form-control"
                      v-model="addresses.pincode"
                      type="text"
                      v-bind:placeholder="'Pincode'"
                    />
                    <ErrorMessage name="pincode" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseAddress()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="address-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="rejectApplication"
    tabindex="-1"
    role="dialog"
    aria-labelledby="rejectApplication"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseRejectModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure, you want to reject the application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseRejectModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            type="button"
            @click="onUpdateStatus('REJECTED')"
            class="btn btn-brand-01 wb-save-btn"
            id="status-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="openRejectNarration"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openRejectNarration"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'REJECTED'"
        >
          <h5 class="modal-title font-22">
            <span>Reason</span>
          </h5>
        </div>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'APPROVED'"
        >
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <Form
          @submit="onAddRejectNarration"
          class="columns column is-multiline is-12"
          ref="onAddRejectNarration"
        >
          <div class="modal-body text-center" v-if="reason_type == 'APPROVED'">
            <h5>Are you sure you want to verify this application ?</h5>
          </div>
          <div
            class="modal-body form-style pb-0 px-lg-5"
            v-if="reason_type == 'REJECTED'"
          >
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Reason
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="narration"
                  rules="required:reason,true"
                  :validateOnInput="true"
                  v-model="narration"
                >
                  <textarea
                    v-bind="field"
                    rows="5"
                    cols="5"
                    valueProp="id"
                    placeholder="Enter Reject Reason"
                    class="form-control"
                    v-model="narration"
                  />
                </Field>
                <ErrorMessage name="narration" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeNarrationModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-cancel-btn"
              id="confirmation-btn"
              v-if="reason_type == 'APPROVED'"
            >
              Verify
            </button>
            <button
              type="submit"
              class="btn btn-danger wb-cancel-btn"
              id="confirmation-btn"
              v-if="reason_type == 'REJECTED'"
            >
              Reject
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="statusModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="statusModal"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <h5>Are you sure you want to accept this application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn"
            id="confirmation-btn"
            @click="setStatusUrl('loan/' + this.id + '/verification/status')"
          >
            Accept
          </button>
          <button
            type="button"
            @click="
              onUpdateStatus(
                'REJECTED',
                'loan/' + this.id + '/verification/status'
              )
            "
            class="btn btn-danger wb-cancel-btn"
            id="confirmation-btn"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="openDeleteModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openDeleteModel"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeDeleteModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <h5>Are you sure you want to delete?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            @click="closeDeleteModal()"
          >
            No
          </button>
          <button
            type="button"
            @click="onDeleteKycDocumentAPI()"
            class="btn btn-brand-01 wb-cancel-btn"
            id="delete_kyc_document"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="ResetLoanDetail"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ResetLoanDetail"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseResetLoanDetail()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Applicant Info</span>
          </h5>
        </div>
        <Form
          @submit="OnReSetLoanDetails"
          class="columns column"
          ref="ResetLoanDetail"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <label> Name <span class="text-danger">*</span> </label>
                <Field
                  name="title"
                  v-model="loanApplication.name"
                  class="form-control"
                  rules="required:name"
                  :validateOnInput="true"
                  type="text"
                  placeholder="Name"
                  autocapitalize="false"
                />
                <ErrorMessage name="title" class="validation-msg" />
              </div>
              <!-- <div class="col-lg-12 mb-4">
              <label>Email </label>
              <Field name="email" class="form-control" rules="email" :validateOnInput="true"
                  v-model="loanApplication.email" type="text" placeholder="Email"
                  autocapitalize="false" />
              <ErrorMessage name="email" class="validation-msg" />
          </div> -->
              <div class="col-lg-12 mb-4">
                <label>Mobile Number <span class="text-danger">*</span> </label>
                <Field
                  name="mobile_number"
                  class="form-control"
                  rules="required|phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.mobile_number"
                  type="text"
                  placeholder="Mobile Number"
                  autocapitalize="false"
                />
                <ErrorMessage name="mobile_number" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <label>Alt Mobile Number 1 </label>
                <Field
                  name="alt_mobile_number_1"
                  class="form-control"
                  id="alt_mobile_number_1"
                  rules="phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.alt_mobile_number_1"
                  type="text"
                  placeholder="Alt Mobile Number 1"
                  autocapitalize="false"
                />
                <ErrorMessage
                  name="alt_mobile_number_1"
                  class="validation-msg"
                />
              </div>
              <div class="col-lg-12 col-sm-12 pt-2">
                <label>Alt Mobile Number 2 </label>
                <Field
                  name="alt_mobile_number_2"
                  class="form-control"
                  id="alt_mobile_number_2"
                  rules="phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.alt_mobile_number_2"
                  type="text"
                  placeholder="Alt Mobile Number 2"
                  autocapitalize="false"
                />
                <ErrorMessage
                  name="alt_mobile_number_2"
                  class="validation-msg"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseResetLoanDetail()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn mx-2"
                id="save_basic_detail"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <ConfirmationModal @remove="onDeleteGuarantor" ref="deleteLeadManagement" />
  <div
    class="modal fade wb-modal-wrapper"
    id="onVerify"
    tabindex="-1"
    role="dialog"
    aria-labelledby="onVerify"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseVerify()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>

        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>

        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure you want to verify selected documents?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseVerify()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            @click="onSetVerifyDocument()"
            class="btn btn-brand-01 wb-save-btn"
            id="verification-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="onConfirmation"
    tabindex="-1"
    role="dialog"
    aria-labelledby="onVerify"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseConfirmation()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>

        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>

        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>
            Are you sure you want to
            {{ document_status == 0 ? "reject" : "verify" }} selected document?
          </h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseConfirmation()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            @click="onSetLoanKycDocumentStatus()"
            class="btn btn-brand-01 wb-save-btn"
            id="document-verification-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>

  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<style>
.spinner {
  animation: spin 1s infinite ease-in-out;
  animation: dash 1s infinite ease-in-out;
  border-radius: 50%;
  border-top: 2px solid #fff;
  display: inline-block;
  height: 20px;
  /* margin: calc(50vh - 1em) calc(50vw - 1em); */
  width: 20px;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  name: "ViewApplication",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    VueEasyLightbox,
    VueDatePicker,
  },
  mounted() {
    this.userType = localStorage.getItem("user_type");
    if (this.id) {
      if (localStorage.getItem("guarantor-tab")) {
        this.tab = localStorage.getItem("guarantor-tab");
        if (this.tab == "guarantor") {
          window.$("#tab-2").click();
          setTimeout(() => {
            this.getLOanApplicationInfo();
          }, 500);
        }
      } else {
        this.getLOanApplicationInfo();
      }
      window.$(function () {
        window.$('[data-toggle="tooltip"]').tooltip();
      });

      this.onGetKycDocument();
      this.startbutton = document.getElementById("startbutton");
      if (this.startbutton) {
        this.startbutton.removeEventListener("click", {});
        this.startbutton.addEventListener(
          "click",
          (ev) => {
            this.takepicture();
            ev.preventDefault();
          },
          false
        );
      }
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      tab: "DETAIL",
      loanApplication: {
        name: "",
        email: "",
        mobile_number: "",
        loan_type_id: "",
        emi_interest: "",
        branch_id: "",
        loan_amount: 0,
        application_id: "",
      },
      address: {},
      guarantor: {},
      documents: [],
      checklist: [],
      loanTypeList: [],
      guarantorList: [],
      select_all: 0,
      url: "",
      title: "",
      fileName: "",
      kycDcoument: "",
      applicant_thumb_img: "",
      guarantor_thumb_img: "",
      applicant_photo: "",
      guarantor_photo: "",
      width: 320,
      height: 0,
      streaming: false,
      localStream: null,
      video: "",
      canvas: null,
      photo: null,
      startbutton: null,
      photo_type: "",
      thumb_type: "",
      photo_text: "Open Camera",
      Guarantorwidth: 320,
      Guarantorheight: 0,
      Guarantorstreaming: false,
      isWebcamOpen: false,
      Guarantorvideo: null,
      Guarantorcanvas: null,
      Guarantorphoto: null,
      Guarantorstartbutton: null,
      guarantor_id: "",
      guarantor_uuid: "",
      documentList: [],
      document_narration: "",
      delete_id: "",
      application_status: "",
      showGuarantorPhotot: false,
      maps: null,
      showUploadImage: false,
      showSignatureView: false,
      loan_type_id: "",
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      scanLoader: false,
      occupations: [],
      stateList: [],
      occupation: "",
      guarantor_scan_thumb_img: "",
      addresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      addressType: "",
      homeAdresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      officeAddresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      residentialAddresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      addressId: "",
      applicant_video: null,
      kycDocumentList: [],
      kycDocuments: {
        doc_id: "",
        doc_no: "",
        issue_date: "",
        expiry_date: "",
      },
      documentTitle: "",
      kycDocumentFile: "",
      kycFileName: "",
      has_additional_info: "",
      is_optional: "",
      kycLoanDocuments: [],
      kycGuarantorDocuments: [],
      downloadKycDocument: null,
      kycDocumentType: "ADD",
      kycDocummentUuid: "",
      sanctionAmount: "",
      sanctionNarration: "",
      loan_disburment_amount: "",
      flag: false,
      showUploadImageManually: false,
      change_loan_type: null,
      has_change_narration: false,
      change_narration: "",
      sanction_notes: null,
      userType: null,
      hasFinalApprovalPermission: false,
      running_loan_count: 0,
      reason_type: null,
      status_url: null,
      user_uuid: "",
      generate_document_title: "Generate Document",
      max_approved_amount: 0,
      documentIds: [],
      isShowApproveBtn: false,
      document_id: null,
      document_status: 0,
    };
  },
  methods: {
    selectAll(e) {
      this.documentIds = [];
      this.select_all = 0;
      this.kycLoanDocuments.forEach((doc) => {
        doc.is_checked = e.target.checked ? 1 : 0;
        if (e.target.checked) {
          this.isShowApproveBtn = e.target.checked;
          this.select_all = 1;
          this.documentIds.push(doc.id);
        }
        this.isShowApproveBtn = e.target.checked;
      });
      console.log(this.documentIds);
    },
    checkPermission(e, obj) {
      if (e.target.checked) {
        if (!this.documentIds.includes(obj.id)) {
          this.documentIds.push(obj.id);
        }
        obj.is_checked = 1;
        this.isShowApproveBtn = true;
      } else {
        obj.is_checked = 0;
        this.documentIds = this.documentIds.filter((id) => id !== obj.id);
        this.isShowApproveBtn = this.documentIds.length > 0;
      }
      let hasChecked = this.kycLoanDocuments.every(
        (doc) => doc.is_checked === 1
      );
      if (hasChecked) {
        this.select_all = 1;
      } else {
        this.select_all = 0;
      }
      console.log(this.documentIds);
    },
    onVerify() {
      if (this.documentIds.length > 0) {
        window.$("#onVerify").modal("show");
      } else {
        this.$toast.error("Please select atleast one document", {
          position: "top-right",
        });
      }
    },
    onCloseVerify() {
      window.$("#onVerify").modal("hide");
    },
    onSetVerifyDocument() {
      if (this.documentIds.length == 0) {
        this.$toast.error("Please select at least one document", {
          position: "top-right",
        });
        return;
      }

      var method = "POST";
      var action = "loans/assign/verification-officer";
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.documentIds,
          _buttonId: "verification-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onCloseVerify();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    openConfirmation(e, obj) {
      window.$("#onConfirmation").modal("show");
      this.document_id = obj.uuid;
      this.document_status = e.target.checked ? 1 : 0;
    },
    onCloseConfirmation() {
      window.$("#onConfirmation").modal("hide");
      this.document_id = null;
      this.document_status = 0;
      this.getLOanApplicationInfo();
    },
    onSetLoanKycDocumentStatus() {
      if (this.document_id == null) {
        return this.$toast.error("Please select atleast one document", {
          position: "top-right",
        });
      }
      var method = "POST";
      var action = `loan/${this.id}/document/${this.document_id}/approve`;
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: { status: this.document_status },
          _buttonId: "document-verification-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onCloseConfirmation();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onAddRejectNarration() {
      var button_id = "confirmation-btn";
      this.$api
        .webRequest({
          _method: "PUT",
          _action: this.status_url,
          _buttonId: button_id,
          _body: {
            status: this.reason_type,
            narration: this.narration,
            loan_amount: this.loan_disburment_amount,
            loan_type_id: this.loanApplication.loan_type_id,
          },
        })
        .then((res) => {
          this.onClearForm("onAddRejectNarration");

          var active_tab = localStorage.getItem("loan_application_active_tab");
          console.log(" active tab : ", active_tab);
          if (this.reason_type == "REJECTED") {
            localStorage.setItem("loan_application_active_tab", "REJECTED");
            this.$router.push("/loan_applications");
          }
          if (this.reason_type == "APPROVED") {
            if (active_tab == "PENDING") {
              localStorage.setItem(
                "loan_application_active_tab",
                "PENDING_FOR_VERIFICATION"
              );
              this.$router.push("/loan_applications");
            }
            if (active_tab == "PENDING_FOR_VERIFICATION") {
              localStorage.setItem(
                "loan_application_active_tab",
                "DOCUMENT_VERIFIED"
              );
              this.$router.push("/loan_applications");
            }
            if (active_tab == "DOCUMENT_VERIFIED") {
              localStorage.setItem(
                "loan_application_active_tab",
                "PENDING_FOR_FINAL_APPROVAL"
              );
              this.$router.push("/loan_applications");
            }

            if (active_tab == "PENDING_FOR_FINAL_APPROVAL") {
              localStorage.setItem(
                "loan_application_active_tab",
                "FINAL_APPROVAL"
              );
              this.$router.push("/loan_applications");
            }
          }

          this.closeNarrationModal();
          this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUpdateStatus(status, url) {
      if (this.application_status == "PENDING_FOR_FINAL_APPROVAL") {
        url = "loan/" + this.id + "/document-verification/status";
      }
      if (this.application_status == "DOCUMENT_VERIFIED") {
        url = "loan/" + this.id + "/document/status";
      }
      if (this.application_status != "PENDING") {
        if (this.kycLoanDocuments.length > 0) {
          var requiredDoc = this.kycDocumentList.filter((obj) => {
            if (obj.is_optional === 0) {
              return obj.id;
            }
          });

          var submittedDoc = this.kycLoanDocuments.filter((obj) => {
            if (obj.doc.is_optional === 0) {
              return obj.doc.id;
            }
          });
          //Check Unique Documents

          if (requiredDoc.length != submittedDoc.length) {
            this.$toast.error(
              "Please upload required Aadhaar & PAN Card documents for KYC verification.",
              {
                position: "top-right",
              }
            );
            return true;
          }
        } else {
          this.$toast.error(
            "Please upload required Aadhaar & PAN Card documents for KYC verification.",
            {
              position: "top-right",
            }
          );
          return true;
        }
      }

      this.reason_type = status;
      this.status_url = url;
      window.$("#openRejectNarration").modal("show");
      if (status == "REJECTED") {
        this.reason_type = status;
        window.$("#statusModal").modal("hide");
        window.$("#rejectApplication").modal("hide");
        window.$("#openRejectNarration").modal("show");
        return false;
      }
    },
    setStatusUrl(url) {
      this.reason_type = "APPROVED";
      this.status_url = url;
      this.onAddRejectNarration();
    },
    onNarrtion() {
      window.$("#openRejectNarration").modal("show");
    },
    closeNarrationModal() {
      this.narration = null;
      this.reason_type = null;
      window.$("#openRejectNarration").modal("hide");
      window.$("#statusModal").modal("hide");

      if (this.reason_type == "REJECTED") {
        this.onClearForm("onAddRejectNarration");
      }
    },

    onRejectModal() {
      window.$("#rejectApplication").modal("show");
      this.officer_uuid = this.loanApplication.verification_officer.uuid;
    },
    onCloseRejectModal() {
      window.$("#rejectApplication").modal("hide");
      this.officer_uuid = null;
    },
    changeLoanType(e) {
      if (e.target.checked) {
        this.change_loan_type = 1;
        this.has_change_narration = true;
      } else {
        this.change_loan_type = 0;
        this.has_change_narration = false;
      }
    },
    OpenUploadApplicantVideo(e) {
      console.log("called", e);
      window.$("#uploadApplicantVideo").click();
      // e.stopImmediatePropagation();
      this.applicant_video = 1;
    },
    OpenMapModel(item) {
      this.maps = item;
      window.$("#openGooglemap").modal("show");
    },
    closeMapModal() {
      this.maps = null;
      window.$("#openGooglemap").modal("hide");
    },
    onDeleteEvent(item) {
      this.delete_id = item.uuid;
      this.$refs.deleteLeadManagement.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      );
    },

    onDeleteGuarantor() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "loan/" + this.id + "/guarantor/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteLeadManagement.closeModal();
            this.getLOanApplicationInfo();
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteLeadManagement.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },

    openDeleteModal(item) {
      this.delete_id = item?.id;
      window.$("#openDeleteModel").modal("show");
    },
    closeDeleteModal() {
      this.delete_id = null;
      window.$("#openDeleteModel").modal("hide");
    },
    onDeleteKycDocumentAPI() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "loan/" + this.id + "/kyc-document/" + this.delete_id,
          _button_id: "delete_kyc_document",
        })
        .then((res) => {
          if (res) {
            this.getLOanApplicationInfo();
            this.closeDeleteModal();
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.closeDeleteModal();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    getDocumentList(id) {
      this.documentList = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "select/loan/" + id + "/documents",
        })
        .then((res) => {
          this.documentList = res.list;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    addGuarantor() {
      localStorage.setItem("guarantor-tab", "guarantor");
      this.$router.push("/add-guarantor/" + this.id);
    },

    editGuarantor(item) {
      localStorage.setItem("guarantor-tab", "guarantor");
      this.$router.push("/edit-guarantor/" + this.id + "/" + item.uuid);
    },
    uploadFileGuarantor(file, type) {
      console.log("uploadFileGuarantor ==> ", type);
      if (file) {
        this.$api
          .uploadImageAPI({
            _action:
              "loan/" +
              this.id +
              "/guarantor/" +
              this.guarantor_id +
              "/document",
            _file: file,
            _key: "image",
            _body: { type: type },
          })
          .then(() => {});
      }
    },

    onRemoveApplicantPhoto() {
      this.applicant_photo = "";
      this.showUploadImage = false;
      this.showUploadImageManually = false;
    },
    openCam() {
      if (this.flag) {
        window.$("#canvas").hide();
      }
      window.$("#opencamera").hide();
      window.$("#startbutton").show();
      this.openCamera();
    },
    openCamera() {
      window.$("#canvas").hide();
      this.photo_text = "Take Photo";
      this.application_photo = "";
      this.showViewLiveResultButton();
      if (window.$("#video").hasClass("d-none")) {
        window.$("#video").removeClass("d-none");
        window.$("#video").addClass("d-block");
      }
      this.startup();
    },

    OpenPhotoModal(type) {
      this.photo_type = type;
      this.showUploadImageManually = false;
      window.$("#photoModal").modal("show");

      window.$("#opencamera").show();
      window.$("#startbutton").hide();
      this.applicant_photo = "";
      if (type == "APPLICATION") {
        this.showUploadImage = false;
        if (this.loanApplication.applicant_photo) {
          this.showUploadImage = true;
          this.applicant_photo = this.loanApplication.applicant_photo;
          if (this.showViewLiveResultButton()) {
            return;
            // this.photo_text = "Re-Take";
          } else {
            // this.photo_text = "Take Photo";
          }
        } else {
          this.photo_text = "Open Camera";
        }
      }
    },
    OpenScanModal(type) {
      this.thumb_type = type;
      if (type == "APPLICATION") {
        if (this.loanApplication.applicant_thumb_img) {
          this.applicant_thumb_img = this.loanApplication.applicant_thumb_img;
        } else {
          this.applicant_thumb_img = "";
        }
      }
      this.showSignatureView = true;
      window.$("#thumbModal").modal("show");
    },
    saveThumb() {
      if (this.thumb_type == "APPLICATION") {
        this.loanApplication.applicant_thumb_img = this.applicant_thumb_img;
      }
      this.uploadFile(
        this.applicant_thumb_img,
        "loan/" + this.id + "/upload/thumb",
        "thumb-scan"
      );
    },
    onCloseThumModal() {
      this.applicant_thumb_img = "";
      window.$("#thumbModal").modal("hide");
    },
    savePhoto() {
      if (this.photo_type == "APPLICATION") {
        this.loanApplication.applicant_photo = this.applicant_photo;
      }
      this.uploadFile(
        this.applicant_photo,
        "loan/" + this.id + "/upload/image",
        "image-upload-btn"
      );
      this.onCloseSavePhoto();
    },
    onCloseSavePhoto() {
      this.applicant_photo = "";
      window.$("#video").removeClass("d-block");
      window.$("#video").addClass("d-none");
      this.StopWebCam();
      window.$("#photoModal").modal("hide");
    },
    showViewLiveResultButton() {
      if (window.self !== window.top) {
        // Ensure that if our document is in a frame, we get the user
        // to first open it in its own tab or window. Otherwise, it
        // won't be able to request permission for camera access.
        document.querySelector(".contentarea").remove();
        const button = document.createElement("button");
        button.textContent = "View live result of the example code above";
        document.body.append(button);
        button.addEventListener("click", () => window.open(location.href));
        return true;
      }
      return false;
    },
    StopWebCam() {
      // if (this.video && this.video != null) {
      //   let stream = this.video.srcObject;
      //   let tracks = stream.getTracks();
      //   tracks.forEach((track) => track.stop());
      //   this.video.srcObject = null;
      // }
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.localStream = null;
        this.isWebcamOpen = false;
      }
    },
    startup() {
      if (this.showViewLiveResultButton()) {
        return;
      }
      this.showUploadImage = false;
      this.photo_text = "Take Photo";
      this.video = document.getElementById("video");
      this.canvas = document.getElementById("canvas");
      // this.photo = document.getElementById("photo");
      this.startbutton = document.getElementById("startbutton");

      // window.$("#video").removeClass("d-none");
      // window.$("#video").addClass("d-block");
      if (!this.isWebcamOpen) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.video.srcObject = stream;
            this.video.play();
            this.localStream = stream;
            this.isWebcamOpen = true;
            window.$("#video").attr("visibility", "hidden");
          })
          .catch((err) => {
            console.error(`An error occurred: ${err}`);
          });
      }

      this.video.addEventListener(
        "canplay",
        () => {
          if (!this.streaming) {
            this.height =
              this.video.videoHeight / (this.video.videoWidth / this.width);

            // Firefox currently has a bug where the height can't be read from
            // the video, so we will make assumptions if this happens.

            if (isNaN(this.height)) {
              this.height = this.width / (4 / 3);
            }

            // this.video.setAttribute("width", this.width);
            this.video.setAttribute("width", "100%");
            this.video.setAttribute("height", this.height);
            this.canvas.setAttribute("width", this.width);
            this.canvas.setAttribute("height", this.height);
            this.streaming = true;
          }
        },
        false
      );

      window.$("#video").show();

      this.clearphoto();
    },
    clearphoto() {
      const context = this.canvas.getContext("2d");
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.applicant_photo = "";

      // const data = this.canvas.toDataURL("image/png");
      // this.photo.setAttribute("src", data);
    },

    // Capture a photo by fetching the current contents of the video
    // and drawing it into a canvas, then converting that to a PNG
    // format data URL. By drawing it on an offscreen canvas and then
    // drawing that to the screen, we can change its size and/or apply
    // other changes before drawing it.
    takepicture() {
      const context = this.canvas.getContext("2d");
      if (this.width && this.height) {
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        context.drawImage(this.video, 0, 0, this.width, this.height);
        const data = this.canvas.toDataURL("image/png");
        console.log(data);
        this.applicant_photo = data;
        this.flag = true;
        this.photo_text = "Open Camera";
        this.showUploadImage = true;

        window.$("#opencamera").show();
        window.$("#startbutton").hide();
        window.$("#canvas").show();
        window.$("#video").removeClass("d-block");
        window.$("#video").addClass("d-none");
      } else {
        this.clearphoto();
      }
    },
    addLoanApplication(type) {
      this.scanLoader = true;
      var obj = {
        Timeout: 10000,
      };
      this.$api
        .scan({
          _method: "POST",
          _body: obj,
        })
        .then((res) => {
          this.scanLoader = false;
          console.log(res);
          this.showSignatureView = true;
          if (type == "APPLICATION") {
            this.applicant_thumb_img = "data:image/bmp;base64," + res.BMPBase64;
          } else {
            this.guarantor_thumb_img = "data:image/bmp;base64," + res.BMPBase64;
            this.guarantor_scan_thumb_img = {
              image: "data:image/bmp;base64," + res.BMPBase64,
            };
          }
        })
        .catch(() => {});
    },
    onUploadApplicantVideo(e) {
      const videoSrc = document.querySelector("#video-source");
      const videoTag = document.querySelector("#video-tag");
      let files = e.target.files;
      this.applicant_video = files[0];
      console.log("the video file is :", this.applicant_video);
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          videoSrc.src = e.target.result;
          videoTag.load();
        }.bind(this);
        reader.readAsDataURL(e.target.files[0]);
        this.onUploadApplicantVideoApi();
      }
    },
    getLOanApplicationInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id,
          _body: null,
          _buttonId: "",
          _hide_loader: true,
        })
        .then((res) => {
          if (
            (res?.info?.sanction_notes == null ||
              res?.info?.sanction_notes == "") &&
            this.userType == "DOCUMENT_VERIFY_USER"
          ) {
            return this.$router.go(-1);
          }
          this.max_approved_amount = res?.info?.max_approved_amount
            ? res?.info?.max_approved_amount
            : 0;
          this.loanApplication.sanction_letter = res?.info?.sanction_letter;
          this.loanApplication.loan_agreement = res?.info?.loan_agreement;

          if (
            res?.info?.loan_agreement === null ||
            res?.info?.sanction_letter === null
          ) {
            this.generate_document_title = "Generate Document";
          }

          if (
            res?.info?.loan_agreement !== null &&
            res?.info?.sanction_letter !== null
          ) {
            this.generate_document_title = "Re-Generate document";
          }
          if (res?.access?.can_read == 0) {
            return this.$router.go(-1);
          }
          this.access = res?.access;
          this.user_uuid = res?.info?.user?.uuid;
          this.sanction_notes = res?.info?.sanction_notes;
          this.running_loan_count = res?.info?.running_loan_count;
          this.onGetStateList();
          this.onGetOccupation();
          this.documents = [];
          this.checklist = [];
          this.kycLoanDocuments = res?.info?.kyc_documents;
          this.kycGuarantorDocuments = res?.info?.kyc_documents;
          // this.verification_officer = [];
          this.onCheckUserTier(res?.info?.loan_type_id);
          this.has_change_narration =
            res?.info?.has_loan_type_changed === 1 ? true : false;
          this.user = res?.info?.user;
          this.loanApplication.application_id = res?.info?.application_id;
          this.loanApplication.name = res?.info?.applicant_name;
          this.loanApplication.email = res?.info?.email;
          this.loanApplication.mobile_number = res?.info?.mobile_number;
          this.loanApplication.verification_officer =
            res?.info?.verification_officer;
          this.checkList = res?.info?.checklists;
          this.change_narration = res?.info?.loan_type_change_request;
          if (this.userType === "SUPER_ADMIN" || this.userType === "PARTNER") {
            this.change_loan_type = 0;
          } else {
            this.change_loan_type = res?.info?.has_loan_type_changed;
          }

          this.hasFinalApprovalPermission =
            this.$helperService.checkCanApprovePermission(
              res?.info?.branch?.id,
              res?.info?.has_loan_type_changed
            );
          console.log(
            "this.hasFinalApprovalPermission",
            this.hasFinalApprovalPermission
          );

          setTimeout(() => {
            this.loan_type_id = res?.info?.loan_type_id;
          }, 1000);

          if (res.info.branch) {
            this.loanApplication.branch_id = res?.info?.branch?.id;
          }
          if (res.info.loan_type) {
            this.loanApplication.level =
              res.info.loan_type.title +
              " (" +
              this.$helperService.getTitleCase(res.info.loan_type.type) +
              ")";
          }
          this.applicant_video = res?.info?.applicant_video;
          this.loanApplication.loan_type_id = res?.info?.loan_type_id;
          this.loanApplication.loan_amount = res?.info?.loan_amount;
          this.loan_disburment_amount = res?.info?.disbursed_loan_amount;
          this.requestedLoanAmont = res?.info?.loan_amount;
          this.sanctionAmount = res?.info?.sanction_amount;
          this.sanctionNarration = res?.info?.sanction_narration;
          this.documents = res?.info?.documents;
          this.checklist = res?.info?.checklists;
          this.verification_officer = res?.info?.verification_officer;
          this.guarantorList = res?.info?.guarantors;
          this.loanApplication.applicant_thumb_img =
            res?.info?.applicant_thumb_img;
          this.loanApplication.applicant_photo = res?.info?.applicant_photo;
          this.loanApplication.home_address = res?.info?.home_address;
          this.loanApplication.office_address = res?.info?.office_address;
          this.loanApplication.residential_address =
            res?.info?.residential_address;
          this.loanApplication.user = res?.info?.user;
          if (res.info.home_address != null) {
            this.homeAdresses = res.info.home_address;
            this.homeAdresses.id = res.info.home_address.id;
            this.homeAdresses.pincode = res.info.home_address.pincode;
            this.homeAdresses.address = res?.info?.home_address?.address
              ? res?.info?.home_address?.address
              : "";
            this.homeAdresses.city = res?.info?.home_address?.city
              ? res?.info?.home_address?.city
              : "";
            this.homeAdresses.state_id = res?.info?.home_address?.state_id
              ? res?.info?.home_address?.state_id
              : "";
          }
          if (res.info.office_address != null) {
            this.officeAddresses = res.info.office_address;
            this.officeAddresses.id = res.info.office_address.id;
            this.officeAddresses.pincode = res.info.office_address.pincode;
            this.officeAddresses.address = res?.info?.office_address?.address
              ? res?.info?.office_address?.address
              : "";
            this.officeAddresses.city = res?.info?.office_address?.city
              ? res?.info?.office_address?.city
              : "";
            this.officeAddresses.state_id = res?.info?.office_address?.state_id
              ? res?.info?.office_address?.state_id
              : "";
          }
          if (res.info.residential_address != null) {
            this.residentialAddresses = res.info.residential_address;
            this.residentialAddresses.id = res.info.residential_address.id;
            this.residentialAddresses.pincode =
              res.info.residential_address.pincode;
            this.residentialAddresses.address = res?.info?.residential_address
              ?.address
              ? res?.info?.residential_address?.address
              : "";
            this.residentialAddresses.city = res?.info?.residential_address
              ?.city
              ? res?.info?.residential_address?.city
              : "";
            this.residentialAddresses.state_id = res?.info?.residential_address
              ?.state_id
              ? res?.info?.residential_address?.state_id
              : "";
          }

          this.application_status = res?.info?.status;
          this.showUploadImage = false;
          if (res?.info?.applicant_photo != null) {
            this.showUploadImage = true;
          }
          if (res?.info?.applicant_thumb_img != null) {
            this.showUploadImage = true;
          }
          // this.loanApplication.parent_loan = res?.info?.parent_loan
          //   ? res?.info?.parent_loan
          //   : res?.info?.renew_loan;
        })
        .catch(() => {});
    },
    onCheckUserTier(loan_type_id) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/loan-type",
          _body: {
            email: this.loanApplication.email,
            mobile_number: this.loanApplication.mobile_number,
          },
        })
        .then((res) => {
          this.loanTypeList = [];
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              value: res.list[i].id,
            };
            this.loanTypeList.push(obj);
          }
          this.getDocumentList(loan_type_id);
        })
        .catch(() => {});
    },
    download(url, title) {
      this.url = url;
      this.title = title;
      window.$("#viewModal").modal("show");
    },
    onUplaodDocument() {
      window.$("#uploadLoanDocument").modal("show");
    },
    closeModal() {
      window.$("#uploadLoanDocument").modal("hide");
      this.onClearForm("onUploadLoanDocument");
    },
    selectFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectFile").click();
    },
    onDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name;
        this.kycDcoument = files[0];
      } else {
        this.fileName = null;
        this.kycDcoument = null;
      }
    },
    onUploadLoanDocument() {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/upload/document",
          _key: "image",
          _file: this.kycDcoument,
          _body: {
            title:
              this.loan_title == "Other"
                ? this.document_narration
                : this.loan_title,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.closeModal();
          this.getLOanApplicationInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    uploadFile(file, url) {
      if (file) {
        this.$api
          .uploadImageAPI({
            _action: url,
            _file: file,
            _key: "image",
          })
          .then((res) => {
            this.onCloseThumModal();
            this.$toast.success(res.message, { position: "top-right" });
            this.getLOanApplicationInfo();
          })
          .catch((e) => {
            this.$toast.error(e.message, { position: "top-right" });
          });
      }
    },
    save() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/set",
          _body: {
            loan_amount: this.loan_disburment_amount,
            loan_type_id: this.loan_type_id,
            loan_type_change_request: this.change_narration,
            has_loan_type_changed: this.change_loan_type,
            sanction_notes: this.sanction_notes,
          },
          _buttonId: "loan-application-btn",
        })
        .then((res) => {
          this.getLOanApplicationInfo();
          // setTimeout(() => {
          //   if (this.applicant_photo != null) {
          //     this.uploadFile(
          //       this.applicant_photo,
          //       "loan/" + this.id + "/upload/image",
          //       "image-upload-btn"
          //     );
          //   }
          //   if (this.applicant_thumb_img != null) {
          //     this.uploadFile(
          //       this.applicant_thumb_img,
          //       "loan/" + this.id + "/upload/thumb",
          //       "image-upload-btn"
          //     );
          //   }
          // }, 1000);
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    finalApproval() {
      let hasLoanCheckError = false;
      let hasGuarantorCheckError = false;
      this.kycLoanDocuments.forEach((obj) => {
        if (obj.has_checked == 0 && hasLoanCheckError === false) {
          hasLoanCheckError = true;
        }
      });
      if (hasLoanCheckError) {
        return this.$toast.error(
          "Sorry, You can't proceed for approve until all loan kyc documents checked.",
          {
            position: "top-right",
          }
        );
      }

      if (hasGuarantorCheckError) {
        return this.$toast.error(
          "Sorry, You can't proceed for approve until all guarantor kyc documents checked.",
          {
            position: "top-right",
          }
        );
      }
      if (!this.sanction_notes && !this.loanApplication.sanction_notes) {
        this.$toast.error(
          "Sorry, You can't proceed for approve until notes added.",
          {
            position: "top-right",
          }
        );
        return false;
      }

      this.kycLoanDocuments.forEach((obj) => {
        if (obj.has_checked == 0) {
          return this.$toast.error(
            "Sorry, You can't proceed for approve until all loan kyc documents checked.",
            {
              position: "top-right",
            }
          );
        }
      });

      if (!this.applicant_photo && !this.loanApplication.applicant_photo) {
        this.$toast.error("Please upload applicant photo", {
          position: "top-right",
        });
        return false;
      }

      // if (!this.applicant_thumb_img && !this.loanApplication.applicant_thumb_img) {
      //   this.$toast.error("Please upload applicant thumb", {
      //     position: "top-right",
      //   })
      //   return false
      // }

      if (this.guarantorList.length < 2) {
        this.$toast.error("Please add at-least two guarantor.", {
          position: "top-right",
        });
        return false;
      }
      console.log(this.kycLoanDocuments.length, "this.kycLoanDocuments.length");
      if (this.kycLoanDocuments.length > 0) {
        var requiredDoc = this.kycDocumentList
          .filter((obj) => obj.is_optional === 0)
          .map((obj) => obj.id);

        var submittedDoc = this.kycLoanDocuments
          .filter((obj) => obj.doc.is_optional === 0)
          .map((obj) => obj.doc.id);

        // Check if all required documents are submitted
        var allDocsSubmitted = requiredDoc.every((doc) =>
          submittedDoc.includes(doc)
        );

        console.log(requiredDoc, "===", submittedDoc);
        console.log(requiredDoc.length, "===", submittedDoc.length);

        if (!allDocsSubmitted) {
          this.$toast.error(
            "Please upload required Aadhaar & PAN Card documents for KYC verification.",
            {
              position: "top-right",
            }
          );
          return true;
        }
      } else {
        this.$toast.error(
          "Please upload required Aadhaar & PAN Card documents for KYC verification.",
          {
            position: "top-right",
          }
        );
        return true;
      }

      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/document/status",
          _body: {
            status: "APPROVED",
            loan_amount: this.loan_disburment_amount,
            loan_type_id: this.loanApplication.loan_type_id,
          },
          _buttonId: "final-btn",
        })
        .then((res) => {
          if (this.applicant_photo) {
            this.uploadFile(this.applicant_photo, "PHOTO", "final-btn")
          }
          if (this.applicant_thumb_img) {
            this.uploadFile(this.applicant_thumb_img, "THUMB", "final-btn")
          }
          this.$toast.success(res.message, {position: "top-right"})
          var active_tab = localStorage.getItem("loan_application_active_tab")
          console.log(" active tab : ", active_tab)
          if (active_tab == "DOCUMENT_VERIFIED") {
            localStorage.setItem("loan_application_active_tab", "PENDING_FOR_FINAL_APPROVAL")
            this.$router.push("/loan_applications")
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        });
    },
    OpenSelectImage(e) {
      this.applicant_thumb_img = "";
      e.stopImmediatePropagation();
      window.$("#AddPhoto").click();
    },
    onChange(e) {
      let files = e.target.files;
      this.applicant_thumb_img = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          window.$("#img_hoder_4").attr("src", e.target.result);
          this.showSignatureView = true;
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      console.log("Image From The Selected file is:", files[0]);
    },
    uploadThumb() {
      if (this.applicant_photo) {
        this.uploadFile(
          this.applicant_thumb_img,
          "loan/" + this.id + "/upload/thumb",
          "thumb-btn"
        );
        window.$("#photoModal").modal("hide");
      } else {
        this.$toast.error("Please select file", { position: "top-right" });
      }
    },
    OpenTakeSelectImage(e) {
      this.applicant_photo = "";
      e.stopImmediatePropagation();
      this.StopWebCam();
      window.$("#opencamera").show();
      window.$("#startbutton").hide();
      window.$("#video").removeClass("d-block");
      window.$("#video").addClass("d-none");
      window.$("#TakePhotos").click();
      this.showUploadImage = true;
    },
    onTakePhoto(e) {
      let files = e.target.files;
      this.file = files[0];
      this.showUploadImageManually = true;
      this.showUploadImage = true;
      this.applicant_photo = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          window.$("#img_hoder_3").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      // this.uploadFile(files[0], "loan/" + this.id + "/upload/image", "save-btn");
      // window.$("#photoModal").modal("hide");
    },
    openImage(image, index) {
      this.showableImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    downloadPdf(url, filename) {
      if (this.guarantorList.length < 2) {
        this.$toast.error("Please add at-least two guarantor.", {
          position: "top-right",
        });
        return false;
      }

      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    onGenerateFile() {
      // console.log("buttonId >>>>> ", buttonId);
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id + "/generate/agreement",
          _buttonId: "generate-document",
        })
        .then((res) => {
          if (res) {
            this.$toast.success(res.message, { position: "top-right" });
            this.getLOanApplicationInfo();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onGetStateList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/states",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => {});
    },
    onGetOccupation() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/occupations",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.occupations = res.list;
        })
        .catch(() => {});
    },
    onUpdateAddresses() {
      console.log("Adresss Object :", this.addresses);
      if (this.addressType == "HOME_ADDRESS") {
        this.addresses.type = "PERMANENT";
      }
      if (this.addressType == "OFFICE_ADDRESS") {
        this.addresses.type = "OFFICE";
      }
      if (this.addressType == "RESIDENTIAL_ADDRESS") {
        this.addresses.type = "RESIDENTIAL";
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/address/" + this.addressId,
          _buttonId: "address-btn",
          _body: this.addresses,
        })
        .then((res) => {
          this.onClearForm("addressUpdate");
          this.getLOanApplicationInfo();
          this.onCloseAddress();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUpdateAddress(type) {
      this.addressType = type;

      if (type == "HOME_ADDRESS") {
        console.log("address ID  HOME_ADDRESS: ", this.homeAdresses);
        this.addresses = this.homeAdresses;
        this.addressId = this.homeAdresses.id;
      }
      if (type == "OFFICE_ADDRESS") {
        console.log("address ID OFFICE_ADDRESS : ", this.officeAddresses);
        this.addresses = this.officeAddresses;
        this.addressId = this.officeAddresses.id;
      }
      if (type == "RESIDENTIAL_ADDRESS") {
        console.log("address ID OFFICE_ADDRESS : ", this.officeAddresses);
        this.addresses = this.residentialAddresses;
        this.addressId = this.residentialAddresses.id;
      }

      window.$("#viewAddressModal").modal("show");
    },
    onCloseAddress() {
      window.$("#viewAddressModal").modal("hide");
      this.addressType = null;
      this.addresses = {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      };
      // this.homeAdresses = {};
      // this.officeAddresses = {};
      this.addressId = null;
    },
    editKycDocument(item, type) {
      console.log("item value from edit", item);
      this.kycDocummentUuid = item?.uuid;
      this.kycDocumentType = type;
      this.kycDocuments = {
        doc_id: item?.doc?.uuid,
        doc_no: item.doc_no,
      };
      if (item?.issue_date != null) {
        this.kycDocuments.issue_date = item?.issue_date;
      }
      if (item?.expiry_date != null) {
        this.kycDocuments.expiry_date = item?.expiry_date;
      }
      this.kycDocumentList.forEach((obj) => {
        if (obj.uuid == item?.doc?.uuid) {
          this.documentTitle = obj.code;
        }
      });
      this.downloadKycDocument = item?.doc_img;
      this.kycDcoumentFile = "";
      console.log("edit kyc details : ", item);
      window.$("#uploadKycDocs").modal("show");
    },
    onUplaodKycDocument() {
      window.$("#uploadKycDocs").modal("show");
    },
    onCloseUplaodKycModal() {
      window.$("#uploadKycDocs").modal("hide");
      this.kycDocuments = {
        doc_id: "",
        doc_number: "",
        issue_date: "",
        expiry_date: "",
      };
      this.kycDocumentFile = "";
      this.kycFileName = null;
      this.downloadKycDocument = null;
      this.kycDocummentUuid = "";
    },
    afterValueChange(e) {
      if (this.kycDocumentList.length > 0) {
        this.kycDocumentList.forEach((obj) => {
          if (obj.uuid == e) {
            this.documentTitle = obj.code;
            this.has_additional_info = obj.has_additional_info;
            this.is_optional = obj.is_optional;
            console.log(
              "the value is ",
              obj.title,
              obj.has_additional_info,
              obj.is_optional
            );
          }
        });
      }
    },
    onGetKycDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "kyc-documents",
        })
        .then((res) => {
          this.kycDocumentList = res.list;
        })
        .catch(() => {});
    },
    onUploadkycDetails() {
      if (this.kycDocuments.issue_date) {
        this.kycDocuments.issue_date = moment(
          String(this.kycDocuments.issue_date)
        ).format("Y-MM-DD");
      }
      if (this.kycDocuments.expiry_date) {
        this.kycDocuments.expiry_date = moment(
          String(this.kycDocuments.expiry_date)
        ).format("Y-MM-DD");
      }

      // if (this.kycDocuments) {
      //     console.log("the value of the kyc document is :", this.kycDocuments);
      //     return true;
      // }
      var url = "";
      if (this.kycDocumentType == "EDIT") {
        url = "loan/" + this.id + "/kyc-document/" + this.kycDocummentUuid;
      } else {
        url = "loan/" + this.id + "/kyc-document";
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _body: this.kycDocuments,
          _buttonId: "loan-kyc-document",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          if (typeof this.kycDcoumentFile == "object") {
            this.onUploadKyCFiles(this.kycDcoumentFile, res?.id);
          } else {
            this.onCloseUplaodKycModal();
            this.getLOanApplicationInfo();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    onUploadKyCFiles(file, uuid) {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/kyc-document/" + uuid + "/upload",
          _key: "file",
          _buttonId: "loan-kyc-document",
          _file: this.kycDcoumentFile,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo();
          this.onCloseUplaodKycModal();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    selectKycFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectKycFile").click();
    },
    onKycDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.kycFileName = files[0].name;
        this.kycDcoumentFile = files[0];
        console.log("kyc file:", this.kycDcoumentFile);
      } else {
        this.kycFileName = null;
        this.kycDocumentFile = null;
      }
    },
    onUploadApplicantVideoApi() {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/upload/video",
          _key: "video",
          _buttonId: "upload-applicant-video",
          _file: this.applicant_video,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onSendFinalApprove() {
      console.log("the value is :", this.loan_disburment_amount);
      var method = "PUT";
      var action = `loan/${this.id}/document-verification/status`;

      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: {
            status: "APPROVED",
            loan_amount: this.loan_disburment_amount,
            loan_type_id: this.loanApplication.loan_type_id,
          },
          _buttonId: "final_approve_btn",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          var active_tab = localStorage.getItem("loan_application_active_tab")
          if (active_tab == "PENDING_FOR_FINAL_APPROVAL") {
            localStorage.setItem("loan_application_active_tab", "FINAL_APPROVAL")
            this.$router.push("/loan_applications")
          }
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onCloseResetLoanDetail() {
      window.$("#ResetLoanDetail").modal("hide");
    },
    openResetLoanDetail() {
      window.$("#ResetLoanDetail").modal("show");
    },
    OnReSetLoanDetails() {
      let obj = {
        applicant_name: this.loanApplication.name,
        mobile_number: this.loanApplication.mobile_number,
        alt_mobile_number_1: this.loanApplication.alt_mobile_number_1,
        alt_mobile_number_2: this.loanApplication.alt_mobile_number_2,
      };
      this.$api
        .webRequest({
          _method: "PUT",
          _action: `loan/${this.id}/basic`,
          _body: obj,
          _buttonId: "save_basic_detail",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.onCloseResetLoanDetail();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>

<style>
.table tr td {
  vertical-align: middle;
}

.multiselect-tags-search {
  top: -1px !important;
}
</style>
