<template>
  <div class="worker_serch_warp">
    <div class="row d-flex justify-content-between">
      <div class="col-md-12 pr-2 mx-2">
        <h2 class="content-title">
          <a @click="$router.go(-1)" style="cursor: pointer"
            ><i class="fas fa-angle-left mr-2"></i>
            <span> #{{ loanApplication?.loan?.application_id }} Details</span>
          </a>
        </h2>
      </div>
      <div class="col-lg-12 text-right">
        <a
          v-if="access.can_print == 1"
          title="Loan Agreement Download"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="
            downloadExportedPdf(
              loanApplication?.loan_agreement,
              loanApplication?.application_id
            )
          "
          ><img src="/static/img/download-icon.svg" class="mx-1" alt="" />Loan
          Agreement</a
        >
        <a
          v-if="access.can_print == 1"
          title="Sanction Letter Download"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="
            downloadExportedPdf(
              loanApplication?.sanction_letter,
              loanApplication?.application_id
            )
          "
          ><img
            src="/static/img/download-icon.svg"
            alt=""
            class="mx-1"
          />Sanction Letter</a
        >
        <a
          v-if="access.can_print == 1"
          title="Loan Schedule Download"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="
            downloadExportedPdf(
              loanApplication?.loan_schedule_statement,
              loanApplication?.application_id
            )
          "
          ><img
            src="/static/img/download-icon.svg"
            alt=""
            class="mx-1"
          />Amortization Schedule</a
        >
        <a
          v-if="access.can_print == 1 && loanApplication?.customer_noc != null"
          title="Loan Schedule Download"
          id="customer-noc-btn"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="
            downloadPdf(
              loanApplication?.customer_noc,
              loanApplication?.customer_noc
            )
          "
          ><img
            src="/static/img/download-icon.svg"
            alt=""
            class="mx-1"
          />Customer NOC</a
        >

        <a
          v-if="access.can_print == 1"
          title="Download Amortization"
          id="amortization"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="onAmortization()"
          ><img src="/static/img/download-icon.svg" alt="" class="mx-1" />Bank
          Statement</a
        >
        <a
          v-if="access.can_print == 1"
          title="Download Amortization"
          id="customer-statement-btn"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="onCustomerStatement()"
          ><img
            src="/static/img/download-icon.svg"
            alt=""
            class="mx-1"
          />Customer Statement</a
        >
        <a
          v-if="
            access.can_print == 1 &&
            application_status !== 'LOAN_CLOSED' &&
            loanApplication?.due_installments >= 1
          "
          title="Download Legal Notice"
          id="legal-notice-btn"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="onLegalNotice()"
          ><img src="/static/img/download-icon.svg" alt="" class="mx-1" />Legal
          Notice</a
        >
        <a
          v-if="
            loanApplication?.status == 'LOAN_CLOSED' && access.can_print == 1
          "
          title="NOC Download"
          class="mx-1 btn btn-brand-01 wb-save-btn"
          style="cursor: pointer"
          @click="
            downloadExportedPdf(
              loanApplication?.loan_closure_statement,
              loanApplication?.application_id
            )
          "
          ><img src="/static/img/download-icon.svg" alt="" class="mx-1" />Loan
          Closure</a
        >
      </div>
      <div class="col-lg-12 mx-2">
        <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
          <li class="nav-item">
            <a
              id="tab-1"
              data-toggle="tab"
              href="#tab_1"
              role="tab"
              aria-controls="tab_1"
              aria-selected="true"
              class="nav-link active"
              @click="getLOanApplicationInfo()"
              >Applicant Details</a
            >
          </li>
          <li class="nav-item">
            <a
              id="tab-2"
              data-toggle="tab"
              href="#tab_2"
              role="tab"
              aria-controls="tab_2"
              @click="getLOanApplicationInfo()"
              aria-selected="false"
              class="nav-link"
              >Guarantor Details</a
            >
          </li>
          <li class="nav-item">
            <a
              id="tab-3"
              data-toggle="tab"
              href="#tab_3"
              role="tab"
              aria-controls="tab_3"
              aria-selected="false"
              class="nav-link"
              @click="getTransactionList()"
              >Transaction</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="component-section no-code">
    <div id="myTabContent" class="tab-content">
      <div
        id="tab_1"
        role="tabpanel"
        aria-labelledby="tab-1"
        class="tab-pane fade active show"
      >
        <div class="row mx-2">
          <div class="col-lg-12">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3"
              >
                <h4 class="text-bolder">Basic Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Application Id</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.application_id }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-8"
                    v-if="loanApplication?.loan?.parent_loan != null"
                  >
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Renew Application Id</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{
                            loanApplication?.loan?.parent_loan?.application_id
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">CIN Number</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          <a
                            style="text-decoration: underline; color: #005da9"
                            id="customer-btn"
                            target="_blank"
                            :href="
                              '/customer-detail/' +
                              loanApplication?.loan?.user?.uuid
                            "
                            >{{ loanApplication?.loan?.user?.cin_number }}</a
                          >
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Applicant Name</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.applicant_name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Mobile Number</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.mobile_number }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Alt Mobile Number 1</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.alt_mobile_number_1 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Alt Mobile Number 2</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.alt_mobile_number_2 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Permanent Address</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.home_address?.address }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Office Address</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication?.loan?.office_address?.address }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row">
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <h5 class="label-text">Application Id</h5>
                                                <h5 class="label-text">Name</h5>
                                                <h5 class="label-text">Mobile Number</h5>

                                                <h5 class="label-text">Address</h5>
                                                <h5 class="label-text">Office Address</h5>
                                            </div>
                                            <div class="col-lg-8">
                                                <h5 class="text-bolder">{{ loanApplication?.loan?.application_id }}</h5>
                                                <h5 class="text-bolder">{{ loanApplication?.loan?.applicant_name }}</h5>
                                                <h5 class="text-bolder">{{ loanApplication?.loan?.mobile_number }}</h5>

                                                <h5 class="text-bolder">{{ loanApplication?.loan?.home_address?.address }}
                                                </h5>
                                                <h5 class="text-bolder">{{ loanApplication?.loan?.office_address?.address }}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2" style="cursor:pointer">
                                        <div class="">
                                            <h5 class="text-bolder">Photo</h5>
                                            <img :src="loanApplication?.loan?.applicant_photo" class="img-fluid" width="120"
                                                v-if="loanApplication?.loan"
                                                v-on:click="openImage(loanApplication?.loan?.applicant_photo, 0)" />
                                        </div>
                                    </div>
                                    <div class="col-lg-2" style="cursor:pointer">
                                        <div class="">
                                            <h5 class="text-bolder">Signature</h5>
                                            <img :src="loanApplication?.loan?.applicant_thumb_img" class="img-fluid"
                                                width="120" v-if="loanApplication?.loan"
                                                v-on:click="openImage(loanApplication?.loan?.applicant_thumb_img, 0)" />
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>

          <!-- Loan Details -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3"
              ></div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Loan Type :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.loan?.loan_type?.title }}
                          {{
                            " (" +
                            (loanApplication?.loan?.loan_type?.has_secured == 0
                              ? "Unsecured)"
                              : "Secured)")
                          }}
                          -
                          {{
                            $helperService.getTitleCase(
                              loanApplication?.loan?.loan_type?.level
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Apply Date :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedDateOnly(
                              loanApplication?.loan?.apply_at
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Cycle Date :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedDateOnly(
                              loanApplication?.loan?.cycle_start_date
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Disbursement Date :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedDateOnly(
                              loanApplication?.loan?.disbursed_at
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Disbursement By :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.loan?.disbursed_by?.name }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Requested Loan Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.loan_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Sanction Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(sanctionAmount)
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">
                          Processing Fee({{
                            loanApplication?.loan?.processing_fee_percent
                          }}%) :
                        </h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.processing_fee
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">
                          Processing Fee GST({{
                            loanApplication?.loan?.gst_percent
                          }}%) :
                        </h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.gst
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Disbursed Loan Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.disbursed_loan_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">
                          Disbursed Loan Amount After Round Off :
                        </h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan
                                ?.disbursed_loan_amount_round_off
                            )
                          }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Total Loan Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.total_loan_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Paid Installments :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.loan?.paid_installments }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Paid Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.paid_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Due Installments :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.loan?.due_installments }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">Due Amount :</h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.due_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="label-text">
                          Total Due Amount With Penalty :
                        </h5>
                      </div>
                      <div class="col-lg-6">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.loan?.total_due_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- KYC Details -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <h4 class="text-bolder">KYC Details</h4>
                  </div>
                  <div
                    class="col-lg-6 text-right"
                    v-if="access.can_update == 1"
                  >
                    <a href="javascript:void(0)" @click="onUplaodKycDocument">
                      + Upload</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated At</th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="kycLoanDocuments.length > 0">
                          <tr
                            v-for="(item, i) in kycLoanDocuments"
                            :key="item.id"
                          >
                            <td>{{ item?.title }}</td>
                            <td>
                              {{
                                item?.added_by?.name
                                  ? item?.added_by?.name
                                  : "-"
                              }}
                            </td>
                            <td>
                              <span>
                                {{
                                  $helperService.getFormattedDate(
                                    item?.updated_at
                                  )
                                }}</span
                              >
                            </td>

                            <td style="cursor: pointer">
                              <img
                                v-if="
                                  item.doc_img != null || item.doc_img == ''
                                "
                                :src="
                                  item.doc_img
                                    ? item.doc_img
                                    : '/static/img/placeholder.png'
                                "
                                class="img-rounded"
                                style="width: 50px"
                                alt=""
                                v-on:click="openImage(item.doc_img, i)"
                              />

                              <a
                                href="javascript:void(0)"
                                v-if="item.doc_img != null"
                                @click="downloadPdf(item.doc_img, item.title)"
                                class="mx-2"
                                >Download</a
                              >
                              <a
                                href="javascript:void(0)"
                                @click="editKycDocument(item, 'EDIT')"
                                class="mx-2"
                                >Edit</a
                              >
                            </td>
                          </tr>
                        </tbody>
                        <template v-else>
                          <tr>
                            <td colspan="4">
                              <div class="text-center m-5">
                                <h3>Sorry,record not found.</h3>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Loan Documents -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <h4 class="text-bolder">Loan Documents</h4>
                  </div>
                  <div
                    class="col-lg-6 text-right"
                    v-if="access.can_update == 1"
                  >
                    <a href="javascript:void(0)" @click="onUplaodDocument">
                      + Upload</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated At</th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="documents.length > 0">
                          <tr v-for="(item, i) in documents" :key="item.id">
                            <td>{{ item?.title }}</td>
                            <td>
                              {{
                                item?.added_by?.name
                                  ? item?.added_by?.name
                                  : "-"
                              }}
                            </td>
                            <td>
                              <span>
                                {{
                                  $helperService.getFormattedDate(
                                    item?.updated_at
                                  )
                                }}</span
                              >
                            </td>

                            <td
                              v-if="item.image != null || item.image == ''"
                              style="cursor: pointer"
                            >
                              <img
                                :src="
                                  item.image
                                    ? item.image
                                    : '/static/img/placeholder.png'
                                "
                                class="img-rounded"
                                style="width: 50px"
                                alt=""
                                v-on:click="openImage(item.image, i)"
                              />

                              <a
                                href="javascript:void(0)"
                                @click="downloadPdf(item.image, item.title)"
                                class="mx-2"
                                >Download</a
                              >
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                        <template v-else>
                          <tr>
                            <td colspan="4">
                              <div class="text-center m-5">
                                <h3>Sorry,record not found.</h3>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sttlement Details -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3"
              >
                <h4 class="text-bolder">Settlement Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Requested Date :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedDate(
                              loanApplication.created_at
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Status :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getTitleCase(loanApplication?.status)
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Due Amount :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.settlement_due_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Added By :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.added_by?.name }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Settlement Amount :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedCurrency(
                              loanApplication?.settlement_amount
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6"
                    v-if="loanApplication?.status != 'PENDING'"
                  >
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Approved By :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.approved_by_user?.name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Comment :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{ loanApplication?.settlement_comment }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6"
                    v-if="loanApplication.completed_at != null"
                  >
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Complete Date :</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder mx-3">
                          {{
                            $helperService.getFormattedDate(
                              loanApplication.completed_at
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-12 pt-3">
                        <div class="card rounded-2" style="border-radius: 10px;">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <h5 class="label-text">Due Amount</h5>
                                        <h5 class="label-text">Settlement Amount</h5>
                                        <h4 class="label-text">Reason For Settlement</h4>
                                    </div>
                                    <div class="col-lg-9">
                                        <h5 class="text-bolder">
                                            {{ $helperService.getFormattedCurrency(loanApplication.due_amount) }}
                                        </h5>
                                        <h5 class="text-bolder">
                                            {{ $helperService.getFormattedCurrency(loanApplication.settlement_amount) }}
                                        </h5>
                                        <h5 class="text-bolder">
                                            {{ loanApplication.settlement_comment }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <div
            class="col-lg-12 pt-3"
            v-if="loanApplication.status == 'APPROVED'"
          >
            <div class="card rounded-2" style="border-radius: 10px">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Approved Name</h5>
                    <h5 class="label-text">Approved Date</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication?.approved_by_user?.name }}
                    </h5>
                    <h5 class="text-bolder">
                      {{
                        $helperService.getFormattedDate(
                          loanApplication?.approved_at
                        )
                      }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-12 pt-3"
            v-if="loanApplication.status == 'REJECTED'"
          >
            <div class="card rounded-2" style="border-radius: 10px">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Rejected Name</h5>
                    <h5 class="label-text">Rejected Date</h5>
                    <h5 class="label-text">Rejected reason</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication.rejected_by_user?.name }}
                    </h5>
                    <h5 class="text-bolder">
                      {{
                        $helperService.getFormattedDate(
                          loanApplication.rejected_at
                        )
                      }}
                    </h5>
                    <h5 class="text-bolder">{{ loanApplication.narration }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 pt-3" v-if="application_status == 'PENDING'">
            <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <button
                  id="cancel-btn"
                  type="button"
                  @click="onUpdateStatus('REJECTED')"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                >
                  Reject
                </button>
                <button
                  type="submit"
                  @click="onUpdateStatus('APPROVED')"
                  class="btn btn-brand-01 wb-save-btn"
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="tab_2"
        role="tabpanel"
        aria-labelledby="tab-2"
        class="tab-pane fade"
      >
        <div class="row">
          <div class="col-lg-12 px-5">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div
                    class="col-12 text-right"
                    v-if="application_status == 'DOCUMENT_VERIFIED'"
                  >
                    <a @click="addGuarantor()" class="btn btn-brand-02"
                      >+ Add
                    </a>
                  </div>
                </div>
                <hr />
                <div class="table-responsive" style="min-height: auto">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th>Guarantor Name</th>
                        <th>Guarantor No.</th>
                        <th>Photo</th>
                        <th>Signature</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="guarantorList.length > 0">
                      <tr
                        v-for="guarantor in guarantorList"
                        :key="guarantor.id"
                      >
                        <td>{{ guarantor.guarantor_name }}</td>
                        <td>
                          +91 {{ guarantor.mobile_number }}
                          {{
                            guarantor.alt_mobile_number_1
                              ? ", " + guarantor.alt_mobile_number_1
                              : ""
                          }}
                          {{
                            guarantor.alt_mobile_number_2
                              ? ", " + guarantor.alt_mobile_number_2
                              : ""
                          }}
                        </td>
                        <td>
                          <img
                            :src="guarantor.guarantor_photo"
                            class="img-fluid"
                            width="80"
                            height="80"
                            v-if="guarantor.guarantor_photo"
                          />
                        </td>
                        <td>
                          <img
                            :src="guarantor.guarantor_thumb_img"
                            class="img-fluid"
                            width="50"
                            height="50"
                            v-if="guarantor.guarantor_thumb_img"
                          />
                        </td>
                        <td>
                          <a
                            title="view"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="editGuarantor(guarantor)"
                            ><img src="/static/img/eye-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <template v-if="guarantorList.length == 0">
                    <div class="text-center m-5">
                      <h3>Sorry,record not found.</h3>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="tab_3"
        role="tabpanel"
        aria-labelledby="tab-3"
        class="tab-pane fade"
      >
        <div class="card mx-4 rounded-5">
          <div class="card-body p-3 p-lg-3">
            <div class="row">
              <div class="col-lg-12 mb-2 text-right">
                <button
                  type="button"
                  id="save-btn"
                  @click="
                    $router.push('/add-payment/' + this.loanApplication.loan?.uuid)
                  "
                  class="btn btn-brand-01 wb-save-btn"
                >
                  <i class="fa fa-plus"></i>&nbsp; Add Payment
                </button>
              </div>
            </div>
            <div class="table-responsive" style="min-height: auto">
              <table class="table table-primary mg-b-0">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Credit</th>
                    <th>Debit</th>
                  </tr>
                </thead>
                <tbody v-if="transactions.length > 0">
                  <tr v-for="item in transactions" :key="item.id">
                    <td>
                      {{ $helperService.getFormattedDateOnly(item?.DATE) }}
                    </td>
                    <td>{{ item?.title }}</td>
                    <td v-if="item.credit != '0'">
                      {{ $helperService.getFormattedCurrency(item?.credit) }}
                    </td>
                    <td v-else></td>
                    <td v-if="item.debit != '0'">
                      {{ $helperService.getFormattedCurrency(item?.debit) }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr v-if="transactions.length > 0">
                    <td
                      colspan="2"
                      style="
                        font-weight: 600;
                        border: 1px solid white;
                        border-collapse: collapse 10px;
                        background-color: #96d4d4;
                      "
                      class="text-right"
                    >
                      Sub Total:
                    </td>
                    <td
                      class="text-right"
                      style="
                        font-weight: 600;
                        border: 1px solid white;
                        border-collapse: collapse 10px;
                        background-color: #96d4d4;
                      "
                    >
                      {{ $helperService.getFormattedCurrency(credit_total) }}
                    </td>
                    <td
                      class="text-right"
                      style="
                        font-weight: 600;
                        border: 1px solid white;
                        border-collapse: collapse 10px;
                        background-color: #96d4d4;
                      "
                    >
                      {{ $helperService.getFormattedCurrency(debit_total) }}
                    </td>
                  </tr>
                  <tr v-if="transactions.length > 0">
                    <td
                      colspan="2"
                      style="
                        font-weight: 600;
                        border: 1px solid white;
                        border-collapse: collapse 10px;
                        background-color: #96d4d4;
                      "
                      class="text-right"
                    >
                      Balance:
                    </td>
                    <td
                      colspan="2"
                      class="text-right"
                      style="
                        font-weight: 600;
                        border: 1px solid white;
                        border-collapse: collapse 10px;
                        background-color: #96d4d4;
                      "
                    >
                      {{
                        $helperService.getFormattedCurrency(total_due_amount)
                      }}
                    </td>
                  </tr>
                </tbody>
                <tr v-else>
                  <td colspan="4" class="text-center">
                    <div
                      class="spinner-border spinner-color"
                      v-if="loader"
                      role="status"
                    ></div>
                    <h5 v-else>Sorry, There is no record found.</h5>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="viewModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <img :src="url" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadLoanDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadLoanDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadLoanDocument"
          class="columns column is-multiline is-12"
          ref="onUploadLoanDocument"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="loan_title"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="title"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="loan_title"
                    :options="documentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4" v-if="loan_title == 'Other'">
                <div class="form-group">
                  <label
                    >Document Title
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="loan_title"
                    v-model="document_narration"
                    class="form-control"
                    rules="required:document title"
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document Title"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="loan_title" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document Type
                    <span class="text-danger">*</span>
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectFile($event)"
                  >
                    <Field
                      name="upload_file"
                      v-model="fileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectFile"
                      name="upload_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_file" class="validation-msg" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="update-password"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="openRejectNarration"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openRejectNarration"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'REJECTED'"
        >
          <h5 class="modal-title font-22">
            <span>Reason</span>
          </h5>
        </div>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'APPROVED'"
        >
          <h5 class="modal-title font-22">
            <span>Approve</span>
          </h5>
        </div>
        <div class="modal-body text-center" v-if="reason_type == 'APPROVED'">
          <p>Are you sure you want to approve ?</p>
        </div>
        <Form
          @submit="onAddRejectNarration"
          class="columns column is-multiline is-12"
          ref="onAddRejectNarration"
          v-if="reason_type == 'REJECTED'"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Reason
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="level"
                  rules="required:reason,true"
                  :validateOnInput="true"
                  v-model="narration"
                >
                  <textarea
                    v-bind="field"
                    rows="5"
                    cols="5"
                    valueProp="id"
                    placeholder="Enter Reject Reason"
                    class="form-control"
                    v-model="narration"
                  />
                </Field>
                <ErrorMessage name="level" class="validation-msg" />
              </div>
            </div>
          </div>
        </Form>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="closeNarrationModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn"
            id="status-btn"
            v-if="reason_type == 'APPROVED'"
            @click="onAddRejectNarration"
          >
            Approve
          </button>
          <button
            type="submit"
            class="btn btn-danger wb-cancel-btn"
            id="status-btn"
            v-if="reason_type == 'REJECTED'"
            @click="onAddRejectNarration"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseUplaodKycModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="kycDocuments.doc_id"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="uuid"
                    @select="afterValueChange()"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="kycDocuments.doc_id"
                    :options="kycDocumentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>
                    Document Number
                    <span class="text-danger" v-if="is_optional == 0">*</span>
                  </label>
                  <Field
                    name="doc_number"
                    v-model="kycDocuments.doc_no"
                    class="form-control"
                    :rules="
                      is_optional == 0
                        ? documentTitle == 'AADHAAR_CARD'
                          ? 'required:document number,true|aadhaar'
                          : documentTitle == 'PAN_CARD'
                          ? 'required:document number,true|PAN'
                          : ''
                        : ''
                    "
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document number"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="doc_number" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Issue Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="issue_date"
                    class="form-control"
                    v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:issue date,true' : ''"
                    id="issue_date"
                    v-model="kycDocuments.issue_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Issue Date "
                      style="height: 46px !important;"
                      v-model="kycDocuments.issue_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="issue_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Expiry Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="expired_date"
                    class="form-control"
                    v-slot="{ date }"
                    :rules="is_optional == 0 ? 'required:expiry date,true' : ''"
                    id="expired_date"
                    v-model="kycDocuments.expiry_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Expired Date "
                      style="height: 46px !important;"
                      v-model="kycDocuments.expiry_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="expired_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectKycFile($event)"
                  >
                    <Field
                      name="upload_kyc_file"
                      v-model="kycFileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectKycFile"
                      name="upload_kyc_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onKycDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectKycFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_kyc_file" class="validation-msg" />
                </div>
                <a
                  v-if="downloadKycDocument != null"
                  style="cursor: pointer"
                  @click="downloadPdf(downloadKycDocument, 'kyc Document')"
                  ><img
                    src="/static/img/download-icon.svg"
                    class="mx-3"
                    alt=""
                  />Download</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="onCloseUplaodKycModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="loan-kyc-document"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="showDownloadAlert"
    tabindex="-1"
    role="dialog"
    aria-labelledby="showDownloadAlert"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div
      class="modal-dialog modal-md modal-dialog-centered modal-md"
      role="document"
    >
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeDownloadAlertPopup()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Download Alert</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <div class="row">
            <div class="col-lg-12">
              Loan Agreement/Sanction Letter or Loan schedule will be available
              soon.
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="closeDownloadAlertPopup()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import { ref } from "vue";
import moment from "moment";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddEditLoanApplication",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueEasyLightbox,
  },
  data() {
    return {
      id: this.$route.params.id,
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      loan_id: this.$route.params.loan_id,
      loanApplication: {},
      user: {},
      branch_ids: [],
      documentType: [],
      loanDocument: [],
      documents: [],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      loan_title: "",
      fileName: "",
      kycDcoument: "",
      narration: "",
      reason_type: "",
      application_status: "",
      guarantorList: [],
      url: "",
      title: "",
      documentList: [],
      document_narration: "",
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      kycDocumentList: [],
      kycDocuments: {
        doc_id: "",
        doc_no: "",
        issue_date: "",
        expiry_date: "",
      },
      documentTitle: "",
      kycDocumentFile: "",
      kycFileName: "",
      has_additional_info: "",
      is_optional: "",
      kycLoanDocuments: [],
      downloadKycDocument: null,
      kycDocumentType: "ADD",
      kycDocummentUuid: "",
      requestedLoanAmont: "",
      sanctionAmount: "",
      sanctionNarration: "",
      transactions: [],
      loader: false,
      credit_total: 0,
      debit_total: 0,
      total_due_amount: 0,
    };
  },

  computed: {
    branchList() {
      return this.$storeService.getters.getBranches;
    },
  },
  mounted() {
    if (this.id && this.loan_id) {
      this.getLOanApplicationInfo();
    }
  },
  methods: {
    getDocumentList(id) {
      this.documentList = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "select/loan/" + id + "/documents",
        })
        .then((res) => {
          this.documentList = res.list;
          var obj = { id: 0, title: "Other" };
          this.documentList.push(obj);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUplaodDocument() {
      window.$("#uploadLoanDocs").modal("show");
    },
    closeModal() {
      window.$("#uploadLoanDocs").modal("hide");
      this.onClearForm("onUploadLoanDocument");
    },
    onNarrtion() {
      window.$("#openRejectNarration").modal("show");
    },
    closeNarrationModal() {
      this.narration = null;
      this.reason_type = null;
      window.$("#openRejectNarration").modal("hide");
      if (this.reason_type == "REJECTED") {
        this.onClearForm("onAddRejectNarration");
      }
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    getLOanApplicationInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.loan_id + "/settlement/" + this.id,
        })
        .then((res) => {
          this.access = res?.access;
          this.documents = [];
          this.kycLoanDocuments = res?.info?.loan?.kyc_documents;
          this.sanctionAmount = res?.info?.loan?.sanction_amount;
          this.sanctionNarration = res?.info?.loan?.sanction_narration;
          this.user = res?.info?.user;
          this.loanApplication = res?.info;
          this.documents = res?.info?.loan?.documents;
          this.loanDocument = res?.info?.loan?.documents;
          this.application_status = res.info.status;
          this.guarantorList = res?.info?.loan?.guarantors;
          this.getDocumentList(res?.info?.loan?.loan_type_id);
        })
        .catch(() => {});
    },
    save() {
      var method = "POST";
      var action = "/loan";
      if (this.id) {
        method = "PUT";
        action = "loan/" + this.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.loanApplication,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditLoanApplication");
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUploadLoanDocument() {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/upload/document",
          _key: "image",
          _file: this.kycDcoument,
          _body: {
            title:
              this.loan_title == "Other"
                ? this.document_narration
                : this.loan_title,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.closeModal();
          this.getLOanApplicationInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUpdateStatus(status) {
      this.reason_type = status;
      window.$("#openRejectNarration").modal("show");
    },
    onAddRejectNarration() {
      var button_id = "status-btn";
      this.$api
        .webRequest({
          _method: "PUT",
          _action:
            "loan/" + this.loan_id + "/settlement/" + this.id + "/status",
          _buttonId: button_id,
          _body: { status: this.reason_type, narration: this.narration },
        })
        .then((res) => {
          this.closeNarrationModal();
          this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo();
          localStorage.setItem("settlement_active_tab", "APPROVED");
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    download(url, title) {
      this.url = url;
      this.title = title;
      window.$("#viewModal").modal("show");
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    selectFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectFile").click();
    },
    onDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name;
        this.kycDcoument = files[0];
      } else {
        this.fileName = null;
        this.kycDcoument = null;
      }
    },
    openImage(image, index) {
      this.showableImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    editKycDocument(item, type) {
      this.kycDocummentUuid = item?.uuid;
      this.kycDocumentType = type;
      this.kycDocuments = {
        doc_id: item?.doc?.uuid,
        doc_no: item.doc_no,
      };
      if (item?.issue_date != null) {
        this.kycDocuments.issue_date = item?.issue_date;
      }
      if (item?.expiry_date != null) {
        this.kycDocuments.expiry_date = item?.expiry_date;
      }
      this.kycDocumentList.forEach((obj) => {
          if (obj.uuid == item?.doc?.uuid) {
            this.documentTitle = obj.code
          }
        });
      this.downloadKycDocument = item?.doc_img;
      this.kycDcoumentFile = "";
      console.log("edit kyc details : ", item);
      window.$("#uploadKycDocs").modal("show");
    },
    onUplaodKycDocument() {
      window.$("#uploadKycDocs").modal("show");
    },
    onCloseUplaodKycModal() {
      window.$("#uploadKycDocs").modal("hide");
      this.kycDocuments = {
        doc_id: "",
        doc_number: "",
        issue_date: "",
        expiry_date: "",
      };
      this.kycDocumentFile = "";
      this.kycFileName = null;
      this.downloadKycDocument = null;
      this.kycDocummentUuid = "";
    },
    afterValueChange() {
      if (this.kycDocumentList.length > 0) {
        this.kycDocumentList.forEach((obj) => {
          if (obj.id == this.kycDocuments.doc_id) {
            this.documentTitle = obj.code;
            this.has_additional_info = obj.has_additional_info;
            this.is_optional = obj.is_optional;
            console.log(
              "the value is ",
              obj.title,
              obj.has_additional_info,
              obj.is_optional
            );
          }
        });
      }
    },
    editGuarantor(item) {
      this.$router.push("/edit-guarantor/" + this.id + "/" + item.uuid);
    },
    onGetKycDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "kyc-documents",
        })
        .then((res) => {
          this.kycDocumentList = res.list;
        })
        .catch(() => {});
    },
    onUploadkycDetails() {
      if (this.kycDocuments.issue_date) {
        this.kycDocuments.issue_date = moment(
          String(this.kycDocuments.issue_date)
        ).format("Y-MM-DD");
      }
      if (this.kycDocuments.expiry_date) {
        this.kycDocuments.expiry_date = moment(
          String(this.kycDocuments.expiry_date)
        ).format("Y-MM-DD");
      }

      // if (this.kycDocuments) {
      //     console.log("the value of the kyc document is :", this.kycDocuments);
      //     return true;
      // }
      var url = "";
      if (this.kycDocumentType == "EDIT") {
        url = "loan/" + this.id + "/kyc-document/" + this.kycDocummentUuid;
      } else {
        url = "loan/" + this.id + "/kyc-document";
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _body: this.kycDocuments,
          _buttonId: "loan-kyc-document",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          if (typeof this.kycDcoumentFile == "object") {
            this.onUploadKyCFiles(this.kycDcoumentFile, res?.id);
          } else {
            this.onCloseUplaodKycModal();
            this.getLOanApplicationInfo();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    onUploadKyCFiles(file, uuid) {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/kyc-document/" + uuid + "/upload",
          _key: "file",
          _buttonId: "loan-kyc-document",
          _file: this.kycDcoumentFile,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo();
          this.onCloseUplaodKycModal();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    selectKycFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectKycFile").click();
    },
    onKycDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.kycFileName = files[0].name;
        this.kycDcoumentFile = files[0];
        console.log("kyc file:", this.kycDcoumentFile);
      } else {
        this.kycFileName = null;
        this.kycDocumentFile = null;
      }
    },
    getTransactionList() {
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.loan_id + "/balance-sheets",
        })
        .then((res) => {
          this.loader = false;
          this.transactions = res?.info?.balance_sheet_transactions;
          this.credit_total = res?.info?.credit_total;
          this.debit_total = res?.info?.debit_total;
          this.total_due_amount = res?.info?.total_due_amount;
        })
        .catch(() => {});
    },
    downloadExportedPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show");
      } else {
        console.log(url, filename);
        var anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = filename;
        anchorElement.target = "_blank";
        document.body.appendChild(anchorElement);
        console.log(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
      }
    },
    closeDownloadAlertPopup() {
      window.$("#showDownloadAlert").modal("hide");
    },
    onAmortization() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.loan_id + "/adv/statement",
          _buttonId: "amortization",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onLegalNotice() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "loan/" + this.loan_id + "/generate/legal-notice",
          _buttonId: "legal-notice-btn",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onCustomerStatement() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "customer-statement-export/" + this.loan_id,
          _buttonId: "customer-statement-btn",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
