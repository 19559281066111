<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item" v-show="user_type !== 'DOCUMENT_VERIFY_USER'">
                  <a id="tab-1" @click="changeTab('PENDING')" data-toggle="tab" href="#tab_1" role="tab"
                    aria-controls="tab_1" aria-selected="true" class="nav-link active">Enquiry</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'DOCUMENT_VERIFY_USER'">
                  <a id="tab-2" @click="changeTab('PENDING_FOR_VERIFICATION')" data-toggle="tab" href="#tab_2"
                    role="tab" aria-controls="tab_2" aria-selected="false" class="nav-link">Pending For Verification</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'ENQUIRY_USER' || user_type === 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-3" @click="changeTab('DOCUMENT_VERIFIED')" data-toggle="tab" href="#tab_3" role="tab"
                    aria-controls="tab_3" aria-selected="false" class="nav-link">Validation</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'ENQUIRY_USER' && user_type !== 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-pending_for_final_approval" @click="changeTab('PENDING_FOR_FINAL_APPROVAL')"
                    data-toggle="tab" href="#tab_pending_for_final_approval" role="tab"
                    aria-controls="tab_pending_for_final_approval" aria-selected="false" class="nav-link">Pending For
                    Final Approval</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'ENQUIRY_USER' && user_type !== 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-4" @click="changeTab('FINAL_APPROVAL')" data-toggle="tab" href="#tab_4" role="tab"
                    aria-controls="tab_4" aria-selected="false" class="nav-link">Final Approval</a>
                </li>
                <li class="nav-item" v-show="hasFullAccess &&
                  user_type !== 'ENQUIRY_USER' &&
                  user_type !== 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-7" @click="changeTab('FINAL_APPROVAL_REQUEST')" data-toggle="tab" href="#tab_7" role="tab"
                    aria-controls="tab_7" aria-selected="false" class="nav-link">Approval for Cycle Date</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'ENQUIRY_USER' && user_type !== 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-5" @click="changeTab('REJECTED')" data-toggle="tab" href="#tab_5" role="tab"
                    aria-controls="tab_5" aria-selected="false" class="nav-link">Rejected</a>
                </li>
                <li class="nav-item" v-show="user_type !== 'ENQUIRY_USER' && user_type !== 'DOCUMENT_VERIFY_USER'
                  ">
                  <a id="tab-6" @click="changeTab('ALL')" data-toggle="tab" href="#tab_6" role="tab"
                    aria-controls="tab_6" aria-selected="false" class="nav-link">All</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div class="worker_serch_warp">
                  <div class="row">
                    <div class="col-lg-2 mb-2">
                      <div class="search-form position-relative">
                        <input type="text" class="form-control" v-model="filterObj.keyword"
                          v-on:blur="this.getList(1, loan_application_active_tab)" placeholder="Search" />
                        <div class="input-group-prepend position-absolute">
                          <button class="" type="button" id="button-addon1">
                            <img src="/static/img/search.svg" width="15" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 mb-2" v-if="hasFullAccess">
                      <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                        <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                          label="name" valueProp="branch_id" @clear="searchVerificationOfficer"
                          placeholder="Select Branch" class="form-control" v-model="filterObj.branch_ids"
                          :options="branchList" searchable="true" />
                      </div>
                    </div>
                    <div class="col-lg-2 mb-2">
                      <VueDatePicker placeholder="Select Search Date Range" locale="en" :format="'dd/MM/yyyy'"
                        style="height: 46px !important" v-model="date" range :type="boolean" :default="true"
                        :enable-time-picker="false">
                      </VueDatePicker>
                    </div>
                    <div class="col-lg-2 mb-2" v-if="loan_application_active_tab == 'PENDING'">
                      <Multiselect ref="source_multiselect" name="source" mode="single" trackBy="title" label="title"
                        valueProp="value" placeholder="Select Source Type" class="form-control"
                        v-model="filterObj.source" :options="sources" searchable="true" />
                    </div>
                    <div class="col-lg-2 mb-2">
                      <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search </a>
                      <a @click="reset()" class="btn btn-brand-03">Reset </a>
                    </div>
                    <div class="col-lg-2 mb-1 text-right">
                      <a @click="addLoanApplication()" class="btn btn-brand-02" v-if="
                        access.can_create == 1 && user_type !== 'DOCUMENT_VERIFY_USER'
                      ">+ Add
                      </a>
                    </div>
                  </div>
                </div>
                <div id="tab_1" role="tabpanel" aria-labelledby="tab-1" class="tab-pane fade active show">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_1')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_1">
                            Export
                          </button>
                          <button v-if="isShowApproveBtn" type="button" class="btn btn-brand-01 wb-cancel-btn"
                            id="status-btn" @click="onSentPendingForVerification()">
                            Send For Verification
                          </button>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div class="" v-if="list.length > 0">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="selectAll($event)" id="create" v-model="select_all" />
                                </div>
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>

                              <th scope="col" class="">User Loan Status</th>
                              <th scope="col" class="">Enquiry Person Name</th>
                              <th scope="col" class="">Source</th>
                              <th scope="col">Last Followup By</th>
                              <th scope="col">Last Followup</th>
                              <th scope="col">Last Followup Date</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="13" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td class="">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  class="'custom-control-input'" @change="checkPermission($event, item)"
                                  :id="'create_' + item?.id" v-model="item.is_checked" />
                              </td>
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                {{ item?.enquired_by?.name }}
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.recent_note?.added_by?.name }}</td>
                              <td>{{ item?.recent_note?.description }}</td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDate(
                                      item?.recent_note?.created_at
                                    )
                                  }}</span>
                              </td>
                              <td>
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="viewLoanApplication(item)"><img src="/static/img/eye-icon.svg" alt="" /></a>

                                <a title="Add Notes" class="mx-2" style="cursor: pointer" v-if="access.can_update == 1"
                                  @click="OpenNotesModel(item)"><img src="/static/img/comment-icon.svg" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(loan_application_active_tab)" ref="PENDING_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="PENDING_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_2" role="tabpanel" aria-labelledby="tab-2" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_2')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_2">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <div class="row mb-3" v-if="showVerification && user_type !== 'ENQUIRY_USER'">
                          <!-- <div class="col-lg-1 d-flex flex-row">
                            <div class="form-check pt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                @change="selectAll($event)"
                                id="create"
                                v-model="select_all"
                                v-bind:true-value="1"
                                v-bind:false-value="0"
                              />
                              <label class="form-check-label" for="create">
                                Select All
                              </label>
                            </div>
                          </div> -->
                          <div class="col-lg-3">
                            <Field v-slot="{ field }" name="officer_uuid" rules="required:verification officer,true"
                              :validateOnInput="true" v-model="officer_uuid">
                              <Multiselect v-bind="field" ref="multiselect" trackBy="name" rules="required" label="name"
                                valueProp="uuid" placeholder="Select Verification Officer" class="form-control"
                                v-model="officer_uuid" :options="officers" :searchable="true" />
                            </Field>
                          </div>
                          <div class="col-lg-3">
                            <button type="button" class="btn btn-brand-01 wb-cancel-btn" id="status-btn"
                              @click="onAssignVerification()">
                              Assign
                            </button>
                          </div>
                        </div>
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th width="30px" v-if="user_type !== 'ENQUIRY_USER'">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" @change="selectAll($event)"
                                    id="create" v-model="select_all" v-bind:true-value="1" v-bind:false-value="0" />
                                  <label class="form-check-label" for="create"> </label>
                                </div>
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th scope="col" class="">Source</th>
                              <th scope="col" class="sorting" @click="sorting($event, 'verification_officer_name')">
                                Verification User
                              </th>

                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <!-- <th scope="col">Last Followup By</th>
                              <th scope="col">Last Followup</th>
                              <th scope="col">Last Followup Date</th> -->
                              <th scope="col" class="">User Loan Status</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="11" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td v-if="user_type !== 'ENQUIRY_USER'">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  class="'custom-control-input'" @change="checkPermission($event, item)"
                                  :id="'create_' + item?.id" v-model="item.is_checked" />
                              </td>
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.verification_officer_name }}</td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <!-- <td>{{ item?.recent_note?.added_by?.name }}</td>
                              <td>{{ item?.recent_note?.description }}</td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDate(
                                      item?.recent_note?.created_at
                                    )
                                  }}</span
                                >
                              </td> -->
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                <!-- <a 
                              title="Edit"
                              class="mx-2"
                              style="cursor: pointer"
                              @click="editLoanApplication(item)"
                              v-if="access.can_update == 1 && item.status != 'REJECTED'"
                              ><img src="/static/img/edit-icon.svg" alt=""
                            /></a> -->
                                <div class="d-flex flex-row">
                                  <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                    @click="viewLoanApplication(item)"><img src="/static/img/eye-icon.svg" alt="" /></a>
                                  <!-- <a
                                    title="Add Notes"
                                    class="mx-2"
                                    style="cursor: pointer"
                                    v-if="access.can_update == 1"
                                    @click="OpenNotesModel(item)"
                                    ><img
                                      src="/static/img/comment-icon.svg"
                                      alt=""
                                  /></a> -->
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="getList(1)" ref="PENDING_FOR_VERIFICATION_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="PENDING_FOR_VERIFICATION_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_3" role="tabpanel" aria-labelledby="tab-3" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_3')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_3">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Sanction Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th>Notes</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="11" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td v-if="user_type == 'DOCUMENT_VERIFY_USER'">
                                {{
                                  this.$helperService.maskNumber(
                                    item?.mobile_number,
                                    item?.sanction_notes,
                                    true
                                  )
                                }}
                              </td>
                              <td v-else>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>

                              <td v-if="user_type == 'DOCUMENT_VERIFY_USER'">
                                {{
                                  $helperService.maskNumber(
                                    $helperService.getFormattedCurrency(item.loan_amount),
                                    item?.sanction_notes,
                                    true
                                  )
                                }}
                              </td>
                              <td v-else>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>

                              <td v-if="user_type == 'DOCUMENT_VERIFY_USER'">
                                {{
                                  $helperService.maskNumber(
                                    $helperService.getFormattedCurrency(
                                      item.sanction_amount
                                    ),
                                    item?.sanction_notes,
                                    true
                                  )
                                }}
                              </td>
                              <td v-else>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.sanction_amount
                                  )
                                }}
                              </td>

                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>{{ item?.sanction_notes }}</td>
                              <td>
                                <!-- <a 
                              title="Edit"
                              class="mx-2"
                              style="cursor: pointer"
                              @click="editLoanApplication(item)"
                              v-if="access.can_update == 1 && item.status != 'REJECTED'"
                              ><img src="/static/img/edit-icon.svg" alt=""
                            /></a> -->
                                <a v-if="
                                  access.can_read == 1 &&
                                  user_type == 'DOCUMENT_VERIFY_USER'
                                " title="View" class="mx-2" style="cursor: pointer"><img
                                    src="/static/img/eye-icon.svg" @click="viewLoanApplication(item)" alt="" v-if="
                                      item?.sanction_notes !== null &&
                                      item?.sanction_notes !== ''
                                    " /></a>
                                <a v-else-if="access.can_read == 1" title="View" class="mx-2"
                                  style="cursor: pointer"><img src="/static/img/eye-icon.svg"
                                    @click="viewLoanApplication(item)" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="getList(1)" ref="DOCUMENT_VERIFIED_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="DOCUMENT_VERIFIED_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_pending_for_final_approval" role="tabpanel"
                  aria-labelledby="tab-pending_for_final_approval" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_4')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_4">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Sanction Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="9" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.sanction_amount
                                  )
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                <!-- <a 
                              title="Edit"
                              class="mx-2"
                              style="cursor: pointer"
                              @click="editLoanApplication(item)"
                              v-if="access.can_update == 1 && item.status != 'REJECTED'"
                              ><img src="/static/img/edit-icon.svg" alt=""
                            /></a> -->
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="viewLoanApplication(item)"><img src="/static/img/eye-icon.svg" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="getList(1)" ref="PENDING_FOR_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="PENDING_FOR_FINAL_APPROVAL_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_4" role="tabpanel" aria-labelledby="tab-4" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_5')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_5">
                            Export
                          </button>
                          <div v-if="isShowApproveBtn">
                            <button type="button" class="btn btn-brand-01 wb-cancel-btn" id="status-btn"
                              @click="onApprove()">
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <!-- <div class="row mb-3">
                          <div
                            class="col-lg-12 text-right"
                            v-if="isShowApproveBtn"
                          >
                            <button v-if="isShowApproveBtn"
                              type="button"
                              class="btn btn-brand-01 wb-cancel-btn"
                              id="status-btn"
                              @click="onApprove()"
                            >
                              Approve
                            </button>
                          </div>
                        </div> -->

                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div class="" v-if="list.length > 0">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="selectAll($event)" id="create" v-model="select_all" />
                                </div>
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Loan Type
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Sanction Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="10" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td class="">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  class="'custom-control-input'" @change="checkPermission($event, item)"
                                  :id="'create_' + item?.id" v-model="item.is_checked" />
                              </td>
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number
                                }}{{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  item?.loan_type?.title + " - (" + $helperService.getTitleCase(item?.loan_type?.type) +
                                  ")"
                                }}
                              </td>

                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    Math.round(item.disbursed_loan_amount)
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.sanction_amount
                                  )
                                }}
                              </td>
                              <td>
                                {{ $helperService.getFormattedDateOnly(item?.apply_at) }}
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="viewLoanApplication(item)"><img src="/static/img/eye-icon.svg" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(loan_application_active_tab)"
                          ref="FINAL_APPROVAL_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="FINAL_APPROVAL_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_5" role="tabpanel" aria-labelledby="tab-5" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_6')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_6">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Sanction Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th>Comment</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="9" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.sanction_amount
                                  )
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>{{ item?.narration }}</td>
                              <td>
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="viewLoanApplication(item)"><img src="/static/img/eye-icon.svg" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(loan_application_active_tab)"
                          ref="REJECTED_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="REJECTED_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_6" role="tabpanel" aria-labelledby="tab-6" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_x')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_x">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.status')">
                                Status
                              </th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="9" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                <span v-if="item?.status == 'PENDING'">Enquiry</span>
                                <span v-else-if="item?.status == 'PENDING_FOR_VERIFICATION'">Pending for
                                  Verification</span>
                                <span v-else-if="item?.status == 'DOCUMENT_VERIFIED'">Validation</span>
                                <span v-else-if="item?.status == 'PENDING_FOR_FINAL_APPROVAL'">Pending for Final
                                  Approval</span>
                                <span v-else-if="item?.status == 'FINAL_APPROVAL'">Final Approval</span>
                                <span v-else-if="item?.status == 'DISBURSED'">Loan Disbursed</span>
                                <span v-else-if="item?.status == 'LOAN_CLOSED'">Loan Closed</span>
                                <span v-else-if="item?.status == 'SETTLEMENT_INITIATED'">Settlement Initiated</span>
                                <span v-else-if="item?.status == 'SETTLEMENT_APPROVED'">Settlement Approved</span>
                                <span v-else-if="item?.status == 'SETTLEMENT_REJECTED'">Settlement Rejected</span>
                                <span v-else>Cancelled</span>
                              </td>
                              <td>
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="commonFunctionForRedirect(item)"><img src="/static/img/eye-icon.svg"
                                    alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(loan_application_active_tab)" ref="ALL_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="ALL_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_7" role="tabpanel" aria-labelledby="tab-7" class="tab-pane fade">
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                          <div class="none-box pt-3 mx-3" v-if="total_loan_count">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              # of Applications :<br />
                              {{ total_loan_count }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_loan_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Applied Amount :<br />
                              {{ $helperService.getFormattedCurrency(total_loan_amount) }}
                            </p>
                          </div>
                          <div class="none-box pt-3 mx-3" v-if="total_sanctioned_amount">
                            <p class="font-weight-bold" style="font-size: 16px !important; color: black !important">
                              Total Sanctioned Amount :<br />
                              {{
                                $helperService.getFormattedCurrency(
                                  total_sanctioned_amount
                                )
                              }}
                            </p>
                          </div>
                          <button v-if="access.can_print == 1" @click="onExportDisbursalReport('disbursement_report_y')"
                            class="btn btn-brand-01 mr-2" id="disbursement_report_y">
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.application_id')
                                ">
                                Application Id
                              </th>
                              <th>Source</th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.applicant_name')
                                ">
                                Customer Name
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'branches.name')">
                                Branch
                              </th>
                              <th scope="col" class="sorting" @click="
                                sorting($event, 'loan_applications.mobile_number')
                                ">
                                Mobile Number
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Requested Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.loan_amount')">
                                Sanction Amount
                              </th>
                              <th scope="col" class="sorting" @click="sorting($event, 'loan_applications.apply_at')">
                                Apply Date
                              </th>
                              <th>User Loan Status</th>
                              <th>Cycle Date</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="8" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>
                                {{ item?.application_id
                                }}<sub class="font-weight-bolder text-danger"
                                  v-if="item?.parent_loan_id != null">&nbsp;(Renew)</sub>
                              </td>
                              <td>
                                {{ $helperService.convertUnderscore(item?.source) }}
                              </td>
                              <td>{{ item?.applicant_name }}</td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{ item?.mobile_number }}
                                {{
                                  item.alt_mobile_number_1
                                    ? ", " + item.alt_mobile_number_1
                                    : ""
                                }}
                                {{
                                  item.alt_mobile_number_2
                                    ? ", " + item.alt_mobile_number_2
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(item.loan_amount)
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.sanction_amount
                                  )
                                }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(item?.apply_at)
                                  }}</span>
                              </td>
                              <td>
                                {{ item?.running_loan_count > 0 ? "Running" : "New" }}
                              </td>
                              <td>
                                <span>
                                  {{
                                    $helperService.getFormattedDateOnly(
                                      item?.cycle_start_date
                                    )
                                  }}</span>
                              </td>
                              <td>
                                <a title="View" class="mx-2" style="cursor: pointer" v-if="access.can_read == 1"
                                  @click="commonFunctionForRedirect(item)"><img src="/static/img/eye-icon.svg"
                                    alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(loan_application_active_tab)"
                          ref="APPROVAL_REQUEST_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="APPROVAL_REQUEST_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteLoanType" ref="deleteLoanType" />
    <div class="modal fade wb-modal-wrapper" id="onApprove" tabindex="-1" role="dialog" aria-labelledby="onApprove"
      aria-hidden="true" style="pointer-events: none">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="approveCloseModal()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Choose 1st EMI Date</span>
            </h5>
          </div>
          <Form @submit="onUploadKycDetails" class="columns column is-multiline is-12" ref="onUploadKycDetails">
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="form-group">
                  <label>Disbursement Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field name="disbursed_at" class="form-control" @change="setActualDate($event)" id="disbursed_at"
                    rules="required:Disbursement Date" :validateOnInput="true" v-model="disbursed_at" type="date"
                    placeholder="Disbursement Date" autocapitalize="false" />
                  <ErrorMessage name="disbursed_at" class="validation-msg" />
                </div>
                <div class="form-group">
                  <label>Cycle Date
                    <span class="text-danger">*</span>
                  </label>
                  <Field :disabled="(user_type == 'BRANCH_MANAGER' ||
                      user_type == 'BRANCH_STAFF' ||
                      user_type == 'DOCUMENT_VERIFY_USER' ||
                      user_type == 'ENQUIRY_USER') &&
                    disable == true
                    " name="cycle_start_date" class="form-control" id="cycle_start_date" rules="required:Cycle date"
                    :validateOnInput="true" v-model="cycle_start_date" type="date" placeholder="Cycle Date"
                    autocapitalize="false" @change="setActualDate($event)" />
                  <ErrorMessage name="cycle_start_date" class="validation-msg" />
                </div>
                <div class="form-group" v-show="user_type == 'BRANCH_MANAGER' || user_type == 'BRANCH_STAFF'">
                  <div class="text-right">
                    <input class="mx-2" id="flexCheckChecked" type="checkbox" v-bind:true-value="1"
                      v-bind:false-value="0" @change="changeCycleDate($event)" v-model="has_cycle_date_changed" />
                    <label class="form-check-label link-underline-primary" for="flexCheckChecked">
                      <u>Change Cycle Date </u>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button id="cancel-btn" @click="approveCloseModal()" type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                Cancel
              </button>
              <button type="submit" class="btn btn-brand-01 wb-save-btn" id="update-cycle-date">
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="onSetVerificationOfficer" tabindex="-1" role="dialog"
      aria-labelledby="onSetVerificationOfficer" aria-hidden="true" style="pointer-events: none">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="onCloseAssignOfficer()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>

          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Confirmation</span>
            </h5>
          </div>

          <div class="modal-body form-style pb-0 px-lg-5">
            <h5>Are you sure you want to assign verification officer?</h5>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button id="cancel-btn" @click="onCloseAssignOfficer()" type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
              No
            </button>
            <button @click="onUpdateVerificationOfficer()" class="btn btn-brand-01 wb-save-btn" id="verification-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="onSentPendingForVerification" tabindex="-1" role="dialog"
      aria-labelledby="onSentPendingForVerification" aria-hidden="true" style="pointer-events: none">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="onCloseSentPendingForVerification()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>

          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Confirmation</span>
            </h5>
          </div>

          <div class="modal-body form-style pb-0 px-lg-5">
            <h5>Are you sure you want to send pending for verification?</h5>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button id="cancel-btn" @click="onCloseSentPendingForVerification()" type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
              No
            </button>
            <button @click="onSetBulkApplicationStatus()" class="btn btn-brand-01 wb-save-btn"
              id="bulk-verification-btn">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="onOpenNotesModel" tabindex="-1" role="dialog"
      aria-labelledby="onOpenNotesModel" aria-hidden="true" style="pointer-events: none">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="closeNotesModel()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <Form @submit="onAddPendingNotes" class="columns column is-multiline is-12" ref="onAddPendingNotes">
            <div class="modal-header justify-content-center">
              <h5 class="modal-title font-22">
                <span>Followup Notes</span>
              </h5>
            </div>

            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="row">
                <div class="col-xl-12">
                  <label>Notes<span class="text-danger">*</span> </label>
                  <Field v-slot="{ field }" name="loan_notes" rules="required:followup note,true" :validateOnInput="true"
                    v-model="loan_notes">
                    <textarea v-bind="field" rows="5" cols="5" valueProp="id" placeholder="Enter followup notes.."
                      class="form-control" v-model="loan_notes" />
                  </Field>
                  <ErrorMessage name="loan_notes" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button id="cancel-btn" @click="closeNotesModel()" type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                Cancel
              </button>
              <button type="submit" class="btn btn-brand-01 wb-save-btn" id="loan_notes">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import Pagination from "@/components/Pagination"
import ErrorComponent from "@/components/ErrorComponent"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import $ from "jquery"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
import { Form, Field, ErrorMessage } from "vee-validate"
import moment from "moment"
export default {
  name: "LoanApplication",
  components: {
    Pagination,
    ErrorComponent,
    VueDatePicker,
    ConfirmationModal,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      total_disbursed_amount: 0,
      total_sanctioned_amount: 0,
      sources: [
        {
          title: "All (With / Without Document)",
          value: "ALL",
        },
        {
          title: "With Document",
          value: "WITH_DOCUMENT",
        },
        {
          title: "Without Document",
          value: "WITHOUT_DOCUMENT",
        },
        {
          title: "From Customer",
          value: "FROM_CUSTOMER",
        },
      ],
      branch_id_from_dashboard: this.$route.params.branch_id,
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        source: "ALL",
        offset: 1,
        sort_by: "loan_applications.apply_at",
        sort_order: "DESC",
        row_per_page: 10,
        level: "",
        status: "",
        from_date: "",
        to_date: "",
        branch_ids: "",
      },
      delete_id: "",
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      loan_application_active_tab: "PENDING",
      loan: [],
      select_all: 0,
      cycle_start_date: "",
      officer_uuid: "",
      officers: [],
      dashboard_selected_branch: "",
      isShowApproveBtn: false,
      loan_uuid: "",
      loan_notes: "",
      total_loan_amount: null,
      total_loan_count: null,
      date: "",
      showVerification: false,
      disable: true,
      user_type: null,
      has_cycle_date_changed: null,
      approvalObj: {
        status: "",
        items: [],
      },
    }
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess
    },
    // user_type() {
    //   return this.$storeService.getters.getUserType;
    // }
  },
  watch: {
    "filterObj.branch_ids": function (newVal, old) {
      if (newVal == null || newVal == "") {
        this.showVerification = false
        this.filterObj.branch_ids = ""
      } else {
        
        this.showVerification = true
        this.onVerificationOfficerList()
      }
      console.log(" from watcher " + newVal + " " + old)
    },
    hasFullAccess: function (newVal, old) {
      if (newVal == false) {
        this.showVerification = true
        this.onVerificationOfficerList()
      }
      console.log(" from watcher " + newVal + " " + old)
    },
    branchList: function (newVal) {
      if (newVal.length === 2) {
        this.filterObj.branch_ids = newVal[1].branch_id;
      }
    }
  },
  mounted() {
  
    this.user_type = localStorage.getItem("user_type")
    this.filterObj.branch_ids = this.branch_id_from_dashboard
      ? this.branch_id_from_dashboard
      : ""
    this.loan_application_active_tab = localStorage.getItem("loan_application_active_tab")
      ? localStorage.getItem("loan_application_active_tab")
      : "PENDING"
    //   if (this.branchList.length === 1) {
    //   this.filterObj.branch_ids = this.branchList.branch_id;
    // }
    if (this.user_type === "DOCUMENT_VERIFY_USER") {
      this.loan_application_active_tab = "DOCUMENT_VERIFIED"
    }
    this.changeTab(this.loan_application_active_tab)
    this.$storeService.commit("setTitle", "Loan Application")

  },
  methods: {
    assignAutoCycleDate() {
      var date = new Date()
      setTimeout(() => {
        if (date.getDate() > 15) {
          let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          let lastDay = new Date(nextMonth - 1)
          this.cycle_start_date = lastDay.toISOString().split("T")[0]
        } else {
          let currentMonth = date.getMonth() + 1
          if (currentMonth < 10) {
            currentMonth = "0" + currentMonth
          }
          this.cycle_start_date = `${date.getFullYear()}-${currentMonth}-15`
          this.cycle_start_date = moment(this.cycle_start_date).format("YYYY-MM-DD")
        }
        console.log(this.cycle_start_date)
      }, 1000)
    },
    changeCycleDate(e) {
      var value = e.target.checked
      if (value == true) {
        this.disable = false
        this.has_cycle_date_changed = 1
      } else {
        this.disable = true
        this.has_cycle_date_changed = 0
      }
    },
    searchVerificationOfficer() {
      this.filterObj.branch_ids = ""
    },
    changeTab(tab) {
      this.loan_application_active_tab = tab
      localStorage.setItem("loan_application_active_tab", tab)
      if (tab == "PENDING") {
        window.$("#tab-1").click()
        this.getList(1, tab)
      } else if (tab == "PENDING_FOR_VERIFICATION") {
        window.$("#tab-2").click()
       
        this.getList(1, tab)
      } else if (tab == "DOCUMENT_VERIFIED") {
        window.$("#tab-3").click()
        this.getList(1, tab)
      } else if (tab == "FINAL_APPROVAL") {
        window.$("#tab-4").click()
        this.getList(1, tab)
      } else if (tab == "REJECTED") {
        window.$("#tab-5").click()
        this.getList(1, tab)
      } else if (tab == "ALL") {
        window.$("#tab-6").click()
        this.getList(1, tab)
      } else if (tab == "FINAL_APPROVAL_REQUEST") {
        window.$("#tab-7").click()
        this.getList(1, tab)
      } else if (tab == "PENDING_FOR_FINAL_APPROVAL") {
        window.$("#tab_pending_for_final_approval").click()
        this.getList(1, tab)
      } else {
        window.$("#tab-1").click()
        this.getList(1, tab)
      }
      // let self = this;
      // addEventListener('keyup', function (event) {
      //   if (event.keyCode === 27) {
      //     self.advanced_search = false;
      //     this.closeModal();
      //   }
      // });

      this.assignAutoCycleDate()
    },
    onSentPendingForVerification() {
      if (this.loan.length > 0) {
        window.$("#onSentPendingForVerification").modal("show")
      } else {
        this.$toast.error("Please select atleast one application", {
          position: "top-right",
        })
      }
    },
    onCloseSentPendingForVerification() {
      window.$("#onSentPendingForVerification").modal("hide")
    },
    selectAll(e) {
      if (e.target.checked) {
        this.isShowApproveBtn = true
        this.loan = []
        for (var i = 0; i < this.list.length; i++) {
          this.list[i].is_checked = 1
          this.loan.push(this.list[i].uuid)
        }
      } else {
        this.loan = []
        this.isShowApproveBtn = false
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_checked = 0
        }
      }
      console.log(this.loan)
    },
    checkPermission(e, obj) {
      if (e.target.checked) {
        this.loan.push(obj.uuid)
        obj.is_checked = 1
        this.isShowApproveBtn = true
      } else {
        this.isShowApproveBtn = false
        for (var i = 0; i < this.loan.length; i++) {
          if (obj.uuid == this.loan[i]) {
            obj.is_checked = 0
            this.loan.splice(i, 1)
          }
        }
      }
      for (var k = 0; k < this.list.length; k++) {
        this.select_all = 1
        if (!this.list[k].is_checked) {
          this.select_all = 0
          break
        }
      }
      if (this.loan.length > 0) {
        this.isShowApproveBtn = true
      }
      console.log(this.loan)
    },
    onApprove() {
      if (this.loan.length > 0) {
        window.$("#onApprove").modal("show")
      } else {
        this.$toast.error("Please select atleast one application", {
          position: "top-right",
        })
      }
    },

    approveCloseModal() {
      window.$("#onApprove").modal("hide")
      this.onClearForm("onUploadKycDetails")
      this.has_cycle_date_changed = 0
      this.disable = true
      this.assignAutoCycleDate()
    },
    onAssignVerification() {
      if (this.loan.length > 0) {
        window.$("#onSetVerificationOfficer").modal("show")
      } else {
        this.$toast.error("Please select atleast one application", {
          position: "top-right",
        })
      }
    },
    onCloseAssignOfficer() {
      this.filterObj.branch_ids = ""
      window.$("#onSetVerificationOfficer").modal("hide")
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm()
    },
    setActualDate(e) {
      console.log(this.disbursed_at)
      // var date = moment(this.disbursed_at).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
      var date = new Date(e.target.value)
      // console.log(date,"date value is here",momentDate);
      this.cycle_start_date = ""

      setTimeout(() => {
        if (date.getDate() > 15) {
          let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          let lastDay = new Date(nextMonth - 1)
          this.cycle_start_date = lastDay.toISOString().split("T")[0]
        } else {
          let currentMonth = date.getMonth() + 1
          if (currentMonth < 10) {
            currentMonth = "0" + currentMonth
          }
          this.cycle_start_date = `${date.getFullYear()}-${currentMonth}-15`
          this.cycle_start_date = moment(this.cycle_start_date).format("YYYY-MM-DD")
        }
        console.log(this.cycle_start_date)
      }, 1000)
    },
    onUploadKycDetails() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "loans/disbursed",
          _buttonId: "update-cycle-date",
          _body: {
            loans: this.loan,
            cycle_start_date: this.cycle_start_date,
            disbursed_at: this.disbursed_at,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" })
          this.approveCloseModal()
          this.getList(1)
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
    reset() {
      this.filterObj = {
        keyword: "",
        source: "ALL",
        offset: 1,
        sort_by: "loan_applications.apply_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: localStorage.getItem("loan_application_active_tab"),
        branch_ids: "",
        from_date: "",
        to_date: "",
      }
      this.date = ""
      this.changeTab(this.loan_application_active_tab)
    },
    onDeleteEvent(id) {
      this.delete_id = id
      this.$refs.deleteLoanType.showModal(
        "Confirmation",
        "Are you sure you want to delete?"
      )
    },
    addLoanApplication() {
      this.$router.push("/add-loan-applications")
    },
    editLoanApplication(item) {
      this.$router.push("/edit-loan-applications/" + item.uuid)
    },
    viewLoanApplication(item) {
      console.log(this.loan_application_active_tab, "this.loan_application_active_tab")
      localStorage.removeItem("guarantor-tab")
      if (
        this.loan_application_active_tab == "DOCUMENT_VERIFIED" ||
        this.loan_application_active_tab == "PENDING_FOR_FINAL_APPROVAL"
      ) {
        this.$router.push("/view-verified-loan-application/" + item.uuid)
      } else {
        this.$router.push("/view-loan-application/" + item.uuid)
      }
    },

    pageChange(page) {
      this.getList(page)
    },
    itemsPerPageChange() {
      var tab = this.loan_application_active_tab
      if (tab == "PENDING") {
        this.itemsPerPage = this.$refs.PENDING_PAGINATION.itemsPerPage
      }
      if (tab == "PENDING_FOR_VERIFICATION") {
        this.itemsPerPage = this.$refs.PENDING_FOR_VERIFICATION_PAGINATION.itemsPerPage
      }
      if (tab == "DOCUMENT_VERIFIED") {
        this.itemsPerPage = this.$refs.DOCUMENT_VERIFIED_PAGINATION.itemsPerPage
      }
      if (tab == "FINAL_APPROVAL") {
        this.itemsPerPage = this.$refs.FINAL_APPROVAL_PAGINATION.itemsPerPage
      }
      if (tab == "PENDING_FOR_FINAL_APPROVAL") {
        this.itemsPerPage = this.$refs.PENDING_FOR_FINAL_APPROVAL_PAGINATION.itemsPerPage
      }
      if (tab == "REJECTED") {
        this.itemsPerPage = this.$refs.REJECTED_PAGINATION.itemsPerPage
      }
      if (tab == "ALL") {
        this.itemsPerPage = this.$refs.ALL_PAGINATION.itemsPerPage
      }
      this.getList(1)
    },

    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count !== null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count)
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle("Sorry, There is no record found.", false)
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0)
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle("Sorry, There is no record found.", true)
          }
        }
      }

      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage
      }
      // var checkValue = true;
      // if (listLength > 0) {
      //   checkValue = false;
      // }
      // if (checkValue) {
      //   if (this.$refs[errorCompRef]) {
      //     this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
      //   }
      // }

      // console.log("setPagination", listLength, count, checkValue);
    },

    retry() {
      this.getList(1)
    },
    getList(page, type, sortBy, orderBy) {
      if (type) {
        this.filterObj.status = type
      }
      this.filterObj.offset = page - 1
      this.currentPage = page
      this.filterObj.row_per_page = this.itemsPerPage

      if (sortBy) {
        this.filterObj.sort_by = sortBy
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy
      }

      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format("Y-MM-DD")
        this.filterObj.to_date = moment(String(this.date[1])).format("Y-MM-DD")
      } else {
        this.filterObj.from_date = ""
        this.filterObj.to_date = ""
      }

      if (!this.filterObj.branch_ids) {
        this.filterObj.branch_ids = ""
      }

      this.list = []
      this.loader = true

      var method = "GET"

      this.$api
        .webRequest({
          _method: method,
          _action: "loans",
          _body: this.filterObj,
          _buttonId: "",
          _hide_loader: true,
        })
        .then((res) => {
          if (res.access.can_read == 0) {
            return this.$router.go(-1)
          }
          this.loader = false
          this.access = res.access
          this.list = res.list
          this.total_sanctioned_amount = res?.total_sanctioned_amount
            ? res?.total_sanctioned_amount
            : 0
          this.total_disbursed_amount = res?.total_disbursed_amount
            ? res?.total_disbursed_amount
            : 0
          this.total_loan_amount = res?.total_loan_amount ? res?.total_loan_amount : 0
          this.total_loan_count = res?.count ? res?.count : 0
          var tab = this.loan_application_active_tab
          if (tab == "PENDING") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PENDING_PAGINATION",
              "PENDING_ERROR_COMPONENT"
            )
          } else if (tab == "PENDING_FOR_VERIFICATION") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PENDING_FOR_VERIFICATION_PAGINATION",
              "PENDING_FOR_VERIFICATION_ERROR_COMPONENT"
            )
          } else if (tab == "DOCUMENT_VERIFIED") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "DOCUMENT_VERIFIED_PAGINATION",
              "DOCUMENT_VERIFIED_ERROR_COMPONENT"
            )
          } else if (tab == "FINAL_APPROVAL_REQUEST") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "APPROVAL_REQUEST_PAGINATION",
              "APPROVAL_REQUEST_ERROR_COMPONENT"
            )
          } else if (tab == "PENDING_FOR_FINAL_APPROVAL") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PENDING_FOR_FINAL_APPROVAL_PAGINATION",
              "PENDING_FOR_ERROR_COMPONENT"
            )
          } else if (tab == "FINAL_APPROVAL") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "FINAL_APPROVAL_PAGINATION",
              "FINAL_APPROVAL_ERROR_COMPONENT"
            )
          } else if (tab == "REJECTED") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "REJECTED_PAGINATION",
              "REJECTED_ERROR_COMPONENT"
            )
          } else if (tab == "ALL") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "ALL_PAGINATION",
              "ALL_ERROR_COMPONENT"
            )
          }
          // this.itemsPerPageChange();
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_checked = 0
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle()
          }
        })
    },
    sorting(e, sortBy) {
      var orderBy = "ASC"

      var className = "sorting_asc"
      var classes = e.target.className
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc"
      }

      $(".sorting_asc").removeClass("sorting_asc")
      $(".sorting_desc").removeClass("sorting_desc")

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc")
        orderBy = "ASC"
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc")
        orderBy = "DESC"
      }
      this.getList(1, this.loan_application_active_tab, sortBy, orderBy)
    },

    // On Delete LoanType
    onDeleteLoanType() {
      var method = "DELETE"
      this.$api
        .webRequest({
          _method: method,
          _action: "loan-type/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteLoanType.closeModal()
            this.getList(1)
            this.$toast.success(res.message, {
              position: "top-right",
            })
          }
        })
        .catch((e) => {
          this.$refs.deleteLoanType.removeLoader()
          this.$toast.error(e.message, {
            position: "top-right",
          })
        })
    },
    onUpdateVerificationOfficer() {
      var data = {
        user_id: this.officer_uuid,
        loan_ids: [this.loan],
        branch_id: this.filterObj.branch_ids,
      }

      if (
        data.branch_id == null ||
        data.branch_id == "" ||
        data.branch_id == "undefined"
      ) {
        this.$toast.error(
          "For changing verification officer, first you have to filter applications by Branch.",
          { position: "top-right" }
        )
        return false
      }
      var method = "POST"
      var action = "loans/assign/verification-officer"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: data,
          _buttonId: "verification-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onCloseAssignOfficer()
          this.getList(1)
          this.filterObj.branch_ids = ""
          this.$toast.success(res.message, { position: "top-right" })
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
    onVerificationOfficerList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/verification-officers",
          _body: { branch_id: this.filterObj.branch_ids },
        })
        .then((res) => {
          this.officers = res.list
        })
        .catch(() => { })
    },
    OpenNotesModel(item) {
      this.loan_uuid = item.uuid
      window.$("#onOpenNotesModel").modal("show")
    },
    closeNotesModel() {
      this.loan_notes = ""
      this.loan_uuid = null
      window.$("#onOpenNotesModel").modal("hide")
    },
    onAddPendingNotes() {
      var method = "PUT"
      var action = "loan/" + this.loan_uuid + "/note"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: { description: this.loan_notes },
          _buttonId: "loan_notes",
          _hide_loader: true,
        })
        .then((res) => {
          this.closeNotesModel()
          this.$toast.success(res.message, { position: "top-right" })
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
    onExportDisbursalReport(buttonId) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: `get-loan-application-export`,
          _buttonId: buttonId,
          _body: this.filterObj,
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name)
            this.$toast.success(res.message, { position: "top-right" })
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show")
      } else {
        console.log(url, filename)
        var anchorElement = document.createElement("a")
        anchorElement.href = url
        anchorElement.download = filename
        anchorElement.target = "_blank"
        document.body.appendChild(anchorElement)
        console.log(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
      }
    },
    commonFunctionForRedirect(item) {
      if (item?.status == "PENDING") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "PENDING_FOR_VERIFICATION") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "DOCUMENT_VERIFIED") {
        this.$router.push("/view-verified-loan-application/" + item?.uuid)
      }
      if (item?.status == "PENDING_FOR_FINAL_APPROVAL") {
        this.$router.push("/view-verified-loan-application/" + item?.uuid)
      }
      if (item?.status == "FINAL_APPROVAL") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "CANCELLED") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "DISBURSED") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "DOCUMENT_REJECTED" || item?.status == "APPLICATION_REJECTED" || item?.status == "REJECTED") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
    },
    onSetBulkApplicationStatus() {
      let obj = {
        status: "APPROVED",
        loans: this.loan,
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: `set/bulk/application/status`,
          _buttonId: "bulk-verification-btn",
          _body: obj,
        })
        .then((res) => {
          this.getList(1)
          this.onCloseSentPendingForVerification()
          this.$toast.success(res.message, { position: "top-right" })
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
  },
}
</script>
