<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="component-section no-code">
          <div class="row d-flex justify-content-between">
            <div class="col-md-2 pr-2">
              <h2 class="content-title">
                <a @click="$router.go(-1)" style="cursor: pointer"
                  ><i class="fas fa-angle-left mr-2"></i><span>Branch Expense</span>
                </a>
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="container-fluid">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-2 mb-2">
                  <div class="search-form position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-model="filterObj.keyword"
                       v-on:blur="getList(1)"
                      placeholder="Search"
                    />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 mb-2">
                  <VueDatePicker
                    placeholder="Select Date Range"
                    style="height: 46px; !important"
                    v-model="date"
                    range
                    :type="boolean"
                    :format="'dd/MM/yyyy'"
                    :default="true"
                    :enable-time-picker="false"
                  >
                  </VueDatePicker>
                </div>
                <div class="col-lg-3 mb-2">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect
                      ref="expense_category_id_multiselect"
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      placeholder="Select Expense Category"
                      class="form-control"
                      v-model="filterObj.expense_category_id"
                      :options="expenseCategoryOption"
                      :searchable="true"
                    />
                  </div>
                </div>
                <div class="col-lg-2 mb-2" v-if="hasFullAccess">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect
                      ref="branch_id_multiselect"
                      name="branch_id"
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="branch_id"
                      placeholder="Select Branch"
                      class="form-control"
                      v-model="filterObj.branch_ids"
                      :options="branchList"
                      searchable="true"
                    />
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                </div>
              </div>
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-12 mb-2 text-right">
                    <a
                      @click="addOfficeExpense()"
                      class="btn btn-brand-02 mx-3"
                      v-if="access.can_create == 1"
                      >+ Add
                    </a>
                    <a
                      @click="onExportExpenses()"
                      class="btn btn-brand-01 mr-2"
                      id="export-expenses"
                      v-if="access.can_print == 1"
                      >+ Export Expenses
                    </a>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'branches.name')"
                        >
                          Branch
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expense_categories.name')"
                        >
                          Expense Type
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expenses.amount')"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expenses.gst_amount')"
                        >
                          GST
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expenses.tds_amount')"
                        >
                          TDS
                        </th>

                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expenses.payment_date')"
                        >
                          Payment Date
                        </th>
                        <th scope="col">Comment</th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'added_users.name')"
                        >
                          Added By
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'expenses.created_at')"
                        >
                          Created Date
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="10" class="text-center">
                          <div class="spinner-border spinner-color" role="status"></div>
                        </td>
                      </tr>
                      <tr v-for="item in list" :key="item.id">
                        <td v-if="item?.branch != null">
                          {{ $helperService.getTitleCase(item?.branch?.name) }}
                        </td>
                        <td v-else>Corporate</td>
                        <td>{{ item?.expense_category?.name }}</td>
                        <td>{{ $helperService.getFormattedCurrency(item.amount) }}</td>
                        <td>
                          {{ $helperService.getFormattedCurrency(item.gst_amount) }}
                        </td>
                        <td>
                          {{ $helperService.getFormattedCurrency(item.tds_amount) }}
                        </td>

                        <td>
                          {{ $helperService.getFormattedDateOnly(item?.payment_date) }}
                        </td>
                        <td>{{ item?.narration }}</td>
                        <td>{{ item?.added_by?.name }}</td>
                        <td>{{ $helperService.getFormattedDate(item?.created_at) }}</td>
                        <td>
                          <a
                            title="View"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="view(item)"
                            ><img src="/static/img/eye-icon.svg" alt=""
                          /></a>
                          &nbsp;&nbsp;
                          <a
                            title="Delete"
                            class="mx-2"
                            v-if="access.can_delete == 1"
                            style="cursor: pointer"
                            @click="onDeleteEvent(item)"
                            ><img src="/static/img/delete-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteExpense" ref="deleteExpense" />
    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">View Expense</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <img :src="url" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination"
import ErrorComponent from "@/components/ErrorComponent"
import $ from "jquery"
import moment from "moment"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
export default {
  name: "AssetManagement",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    Multiselect,
    VueDatePicker,
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      date: "",
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "expenses.created_at",
        sort_order: "desc",
        row_per_page: 10,
        branch_ids: "",
        status: "",
        expense_category_id: "",
      },
      url: "",
    }
  },
  mounted() {
    this.getExpenseCategoryList()
    this.$storeService.commit("setTitle", "Branch Expense")
    this.getList(1)
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess
    },
  },
  methods: {
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "expenses.created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
        branch_ids: "",
      }
      this.getList(1)
    },

    addOfficeExpense() {
      localStorage.setItem("expense_status", "OFFICE")
      this.$router.push("/add-expense")
    },
    view(item) {
      this.url = item.bill_img
      window.$("#viewModal").modal("show")
    },
    pageChange(page) {
      this.getList(page)
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage
      this.getList(1)
    },
    retry() {
      this.getList(1)
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1
      this.currentPage = page
      this.filterObj.row_per_page = this.itemsPerPage
      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format("Y-MM-DD")
        this.filterObj.to_date = moment(String(this.date[1])).format("Y-MM-DD")
      } else {
        this.filterObj.from_date = ""
        this.filterObj.to_date = ""
      }
      if (sortBy) {
        this.filterObj.sort_by = sortBy
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy
      }
      this.filterObj.is_editable = 1
      this.list = []
      this.loader = true
      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: "office/expenses",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false
          if (res.access.can_read == 0) {
            return this.$router.go(-1)
          }
          this.list = res.list
          this.access = res.access
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count)
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0)
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.", true)
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle()
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle()
          }
        })
    },
    sorting(e, sortBy) {
      var orderBy = "ASC"

      var className = "sorting_asc"
      var classes = e.target.className
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc"
      }

      $(".sorting_asc").removeClass("sorting_asc")
      $(".sorting_desc").removeClass("sorting_desc")

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc")
        orderBy = "ASC"
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc")
        orderBy = "DESC"
      }
      this.getList(1, sortBy, orderBy)
    },

    // On Delete AssetManagement
    onDeleteEvent(item) {
      this.delete_id = item.uuid
      this.$refs.deleteExpense.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      )
    },
    onDeleteExpense() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "office/expense/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteExpense.closeModal()
            this.getList(1)
            this.$toast.success(res.message, {
              position: "top-right",
            })
          }
        })
        .catch((e) => {
          this.$refs.deleteExpense.removeLoader()
          this.$toast.error(e.message, {
            position: "top-right",
          })
        })
    },
    getExpenseCategoryList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "expenses/categories",
          _body: {is_editable: 1},
        })
        .then((res) => {
          this.expenseCategoryOption = res.list
        })
        .catch(() => {})
    },

    onExportExpenses() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "expense/export/office",
          _buttonId: "export-expenses",
          _body: this.filterObj,
        })
        .then((res) => {
          if (res) {
            var currentDate = new Date()
            var currDate = moment(currentDate).format("YYYYMMDD")
            let ext = this.$helperService.getFileExtension(res?.file_name);
            this.downloadPdf(res?.file_name, "Office_Expense_Report_" + currDate +"." + ext)
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show")
      } else {
        console.log(url, filename)
        var anchorElement = document.createElement("a")
        anchorElement.href = url
        anchorElement.download = filename
        anchorElement.target = "_blank"
        document.body.appendChild(anchorElement)
        console.log(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
      }
    },
  },
}
</script>
